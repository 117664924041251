
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div   class="modal-dialog" role="document">

        <div class="modal-content composepopupmedia" id="myOverlay" style="width:100%;height:650px">
            <div class="modal-header">
                <h4 class="modal-title" id="exampleModalLabel">New Message</h4>
                <img  *ngIf="expands" src="assets/img1/expand.png" (click)="expand()" alt="" width="18px" style="margin-left: 74%;"/>
                <img  *ngIf="compress" src="assets/img1/compres.png" (click)="compres()" alt="" width="26px" style="margin-left: 86%;"/>

                <button type="button" class="close" (click)="cancelmail()" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
            </div>
            <div class="modal-body" style=" overflow-y: scroll !important;
            max-height: 650px; padding: 0rem;">
                <div class="inbox-body">

                    <div class="col-md-12">


                        <div class="col-md-12" id="divstyle">
                            <label id="labeldiv">To</label>
                            <button class="btn btn-success" style= "margin-left: 395px;" (click)="Selectallmail()">Select All</button>

                            <div id="aboveTagInp">
                                <tag-input ng-disabled="all" id="ShareTagInp" [ngModel]="selectedFriend" name="test" [onlyFromAutocomplete]="false" (onRemove)="onItemRemoved($event)" (onAdd)="onItemAdded($event)" [addOnBlur]="true" [errorMessages]="errorMessages" [validators]="regexp"
                                    [separatorKeyCodes]="[13,188,186,32]">
                                    <!-- 13 - enter, 188 - comma ,186 - semicolon -->
                                    <tag-input-dropdown [showDropdownIfEmpty]="false" [dynamicUpdate]="false" [zIndex]="2000" [focusFirstElement]="true" [displayBy]="'email'" [identifyBy]="'uid'" [autocompleteItems]="frndslist">
                                        <ng-template let-item="item" let-index="index">
                                            {{ item.email }}
                                        </ng-template>
                                    </tag-input-dropdown>
                                </tag-input>
                            </div>

                        </div> <br />

                        <input type="text" class="form-control" placeholder="Subject" name="mailsubject" [(ngModel)]="mailsubject" style="font-size: 20px;" autofocus>
                        <br />

                        <textarea type="text" class="form-control" placeholder="Message" name="message" [(ngModel)]="message"  style="font-size: 20px;line-height: 1.7rem;" rows="4" cols="50"> </textarea>
                        <br /> <br />
                        <label id="actionfunctions">
                            <p data-toggle="modal" data-target="#choosefromhaspitacc">
                              <img src="./assets/img/ellipse182.png"
            style="cursor: pointer;" /> &nbsp; Add Attachment from Blocdrive
                            </p> 
                            <div *ngFor="let fileli of selectedattachments">  
                                {{fileli.documentName}}&nbsp;&nbsp;&nbsp;&nbsp;
                                <i class="fa fa-window-close" aria-hidden="true" (click)="RemoveInternalFile(fileli)"></i>
                            </div><br>
                            <!-- <p>{{filename}}</p>
                             <p>{{filenameone}}</p>
                              <p>{{filenametwo}}</p>
                            <p>{{filenamethree}}</p>
                            <p>{{filenamefour}}</p>
                            <p>{{filenamefive}}</p>
                            <p>{{filenamesix}}</p>
                            <p>{{filenameseven}}</p>
                            <p>{{filenameeigth}}</p>
                            <p>{{filenamenine}}</p>
                            <p>{{filenameten}}</p>
                            <p>{{filenameeleven}}</p>
                            <p>{{filenametwelve}}</p>
                            <p>{{filenamethirteen}}</p>
                            <p>{{filenamefourteen}}</p>
                            <p>{{filenamefifteen}}</p>
                            <p>{{filenamesixteen}}</p>
                            <p>{{filenameseventeen}}</p>
                            <p>{{filenameeighteen}}</p>
                            <p>{{filenamenineteen}}</p>
                            <p>{{filenametwenty}}</p> -->
                          </label><br />
                        <label>Attach file</label>
                        <input type="file" name="avatars" multiple=true (change)="onChangeDocumnets($event)">

                        <!-- <p>{{docname1}}</p> -->
                        <!-- <p>{{docname2}}</p><p>{{docname3}}</p><p>{{docname4}}</p><p>{{docname5}}</p>
                        <p>{{docname6}}</p><p>{{docname7}}</p> <p>{{docname8}}</p><p>{{docname9}}</p>
                        <p>{{docname10}}</p><p>{{docname11}}</p><p>{{docname12}}</p><p>{{docname13}}</p><p>{{docname14}}</p><p>{{docname15}}</p>
                        <p>{{docname16}}</p><p>{{docname17}}</p> <p>{{docname18}}</p><p>{{docname19}}</p>
                        <p>{{docname20}}</p> -->
                        <div *ngIf="progress">
                            <progress id="file" value="50" max="100"> 32% </progress>
                        </div>
                        <div *ngIf="color" style="color: blue;" >
                            <div *ngFor="let file of SelectedFiles">
                                {{file.name}}&nbsp;&nbsp;&nbsp;&nbsp;
                                <i class="fa fa-window-close" aria-hidden="true" (click)="RemoveFile(file)"></i>
                            </div><br>
                        </div>

                        <!-- <div style="text-align: right;">
                            <button type="submit" id="SendBtn" class="btn btn-primary" (click)="sendmail()">SEND</button></div><br /> <br />
                    -->

                    <div *ngIf="friends">
                        <a  (click)="savefrienddetails()"><u style="font-size: 22px;
                            color: blue;">Send Friend Request</u></a>
    
                    </div>
                    </div>

                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="cancelmail()" data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary" (click)="sendmail()" >Send</button>
            </div>
        </div>
    </div>
</div>

<ng-template #addcmailpopup let-d="dismiss" let-c="dismiss">
    <div class="aaa composepopupmedia">
        <div class="col-md-12 row">
            <div class="col-md-2">&nbsp;</div>
            <div class="col-md-4" *ngIf="dismissalert" >
              <div class="alert alert-info"
                style="background-color: #545353;box-shadow: none;left: 245px; position: fixed;top: 550px;width: 350px;">
                <button mat-button type="button" class="close" data-dismiss="alert" aria-label="Close">
                  <i class="material-icons" style="color: white">close</i>
                </button>
                <span style="color: white;margin-top: 6px;">
                  <b style="text-transform: capitalize;font-size: 14px;"> {{notify}}</b></span>
              </div>
            </div>
          </div>
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">New Message</h5>
        <img  *ngIf="expands" src="assets/img1/expand.png" (click)="expand()" alt="" width="18px" style="margin-left: 74%;"/>
        <img  *ngIf="compress" src="assets/img1/compres.png" (click)="compres()" alt="" width="26px" style="margin-left: 86%;"/>

        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
    </div>
    <div class="modal-body" style=" overflow-y: scroll !important;
    max-height: 650px; padding: 0rem;">
        <div class="inbox-body">

            <div class="col-md-12">


                <div class="col-md-12" id="divstyle">
                    <label id="labeldiv">To</label>
                    <label id="labeldiv" style="margin-left: 450px;cursor: pointer;text-decoration: underline;"
                     (click)="ccmail()" title="Add Cc recipients">Cc</label>
                    <!-- <div class="selectallmedia"> -->
                    <!--...... <button class="btn btn-success " style= "margin-left: 395px;" (click)="Selectallmail()">Select All</button> -->

                    <button class="btn btn-success " *ngIf='select' style="margin-left: 460px;padding: 9px 10px"
                    (click)="Select()">Select</button>
                <div *ngIf='showtwobut' style='margin-left: 350px;'>
                    <button class="btn btn-success " style="padding: 4px 3px;border-radius: 12px;margin-top: 2px;"
                        (click)="Selectallmail()">Select All</button>
                        <div class="dropdown">
                            <button class="btn btn-success "
                                style="padding: 4px 3px;border-radius: 12px;margin-left: 2px;margin-top: 2px;"
                                (click)="Select()">Select Individuals</button>
                            <div class="dropdown-content">
                                <div *ngFor="let link of frndslist">
                                    <a (click)="Selectindividualmail(link)">{{link.email}}</a>
                                </div>
                            </div>
                        </div>
                    <div class="dropdown">
                        <button class="btn btn-success "
                            style="padding: 4px 3px;border-radius: 12px;margin-left: 2px;margin-top: 2px;"
                            (click)="Select()">Select by group</button>
                        <div class="dropdown-content">
                            <div *ngFor="let link of GroupList">
                                <a (click)="selectgrpname(link)">{{link.groupName}}</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="notenterprise">
                    <button class="btn btn-success " style= "padding: 4px 3px;margin-left: 430px;" (click)="Selectallmail()">Select All</button> 
                </div>
                <div *ngIf="notenterprise">
                    <div class="dropdown">
                        <button class="btn btn-success "
                            style="padding: 4px 3px;border-radius: 12px;margin-left: 390px;margin-top: 5px;"
                            (click)="Select()">Select Individuals</button>
                        <div class="dropdown-content">
                            <div *ngFor="let link of frndslist">
                                <a (click)="Selectindividualmail(link)">{{link.email}}</a>
                            </div>
                        </div>
                    </div>


                   </div>
                <!-- </div> -->
                    <div id="aboveTagInp">
                        <tag-input 
                        ng-disabled="all" id="ShareTagInp" 
                        [ngModel]="selectedFriend" name="test" 
                        [onlyFromAutocomplete]="false" (onRemove)="onItemRemoved($event)" 
                        (onAdd)="onItemAdded($event)" [addOnBlur]="true" 
                        [errorMessages]="errorMessages" [validators]="regexp"
                            [separatorKeyCodes]="[13,188,186,32]">
                            <!-- 13 - enter, 188 - comma ,186 - semicolon -->
                            <tag-input-dropdown [showDropdownIfEmpty]="false" [dynamicUpdate]="false" 
                            [zIndex]="2000" [focusFirstElement]="true" [displayBy]="'email'" 
                            [identifyBy]="'uid'" [autocompleteItems]="frndslist">
                                <ng-template let-item="item" let-index="index">
                                    {{ item.email }}
                                </ng-template>
                            </tag-input-dropdown>
                        </tag-input>
                    </div>

                    <!-- for adding cc recipients -->
                    <div id="aboveTagInp" *ngIf="showccmail">
                        <label id="labeldiv">Cc</label>
                        <tag-input 
                        ng-disabled="all" id="ShareTagInp" 
                        [ngModel]="selectedFriend1" name="test" 
                        [onlyFromAutocomplete]="false" (onRemove)="onItemRemoved1($event)" 
                        (onAdd)="onItemAdded1($event)" [addOnBlur]="true" 
                        [errorMessages]="errorMessages" [validators]="regexp"
                            [separatorKeyCodes]="[13,188,186,32]">
                            <!-- 13 - enter, 188 - comma ,186 - semicolon -->
                            <tag-input-dropdown [showDropdownIfEmpty]="false" [dynamicUpdate]="false" 
                            [zIndex]="2000" [focusFirstElement]="true" [displayBy]="'email1'" 
                            [identifyBy]="'uid'" [autocompleteItems]="frndslist">
                                <ng-template let-item="item" let-index="index">
                                    {{ item.email1 }}
                                </ng-template>
                            </tag-input-dropdown>
                        </tag-input>
                    </div>

                </div> <br />

                <input type="text" class="form-control" placeholder="Subject" name="mailsubject" [(ngModel)]="mailsubject" style="font-size: 20px;" autofocus>
                <br />

                <textarea type="text" class="form-control" placeholder="Message" name="message" [(ngModel)]="message"  style="font-size: 20px;line-height: 1.7rem;" rows="4" cols="50"> </textarea>
                <br /> <br />
                <label id="actionfunctions">
<div (click)="attapop(choosefromhaspitaccextra)">
                      <img src="./assets/img/ellipse182.png"
    style="cursor: pointer;" /> &nbsp; Add Attachment from Blocdrive
</div>
                    <div *ngFor="let fileli of selectedattachments">  
                        {{fileli.documentName}}&nbsp;&nbsp;&nbsp;&nbsp;
                        <i class="fa fa-window-close" aria-hidden="true" (click)="RemoveInternalFile(fileli)"></i>
                    </div><br>
                    <!-- <p>{{filename}}</p>
                     <p>{{filenameone}}</p>
                      <p>{{filenametwo}}</p>
                    <p>{{filenamethree}}</p>
                    <p>{{filenamefour}}</p>
                    <p>{{filenamefive}}</p>
                    <p>{{filenamesix}}</p>
                    <p>{{filenameseven}}</p>
                    <p>{{filenameeigth}}</p>
                    <p>{{filenamenine}}</p>
                    <p>{{filenameten}}</p>
                    <p>{{filenameeleven}}</p>
                    <p>{{filenametwelve}}</p>
                    <p>{{filenamethirteen}}</p>
                    <p>{{filenamefourteen}}</p>
                    <p>{{filenamefifteen}}</p>
                    <p>{{filenamesixteen}}</p>
                    <p>{{filenameseventeen}}</p>
                    <p>{{filenameeighteen}}</p>
                    <p>{{filenamenineteen}}</p>
                    <p>{{filenametwenty}}</p> -->
                  </label><br />
                <label>Attach file</label>
                <input type="file" name="avatars" multiple=true (change)="onChangeDocumnets($event)">

                <!-- <p>{{docname1}}</p> -->
                <!-- <p>{{docname2}}</p><p>{{docname3}}</p><p>{{docname4}}</p><p>{{docname5}}</p>
                <p>{{docname6}}</p><p>{{docname7}}</p> <p>{{docname8}}</p><p>{{docname9}}</p>
                <p>{{docname10}}</p><p>{{docname11}}</p><p>{{docname12}}</p><p>{{docname13}}</p><p>{{docname14}}</p><p>{{docname15}}</p>
                <p>{{docname16}}</p><p>{{docname17}}</p> <p>{{docname18}}</p><p>{{docname19}}</p>
                <p>{{docname20}}</p> -->
                <div *ngIf="progress">
                    <progress id="file" value="50" max="100"> 32% </progress>
                </div>
                <div *ngIf="color" style="color: blue;" >
                    <div *ngFor="let file of SelectedFiles">
                        {{file.name}}&nbsp;&nbsp;&nbsp;&nbsp;
                        <i class="fa fa-window-close" aria-hidden="true" (click)="RemoveFile(file)"></i>
                    </div><br>
                </div>

                <!-- <div style="text-align: right;">
                    <button type="submit" id="SendBtn" class="btn btn-primary" (click)="sendmail()">SEND</button></div><br /> <br />
            -->

            <div *ngIf="friends">
                <a  (click)="savefrienddetails()"><u style="font-size: 22px;
                    color: blue;">Send Friend Request</u></a>

            </div>
            </div>

        </div>
    </div>
    <br />
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" aria-label="Close" (click)="d('Cross click')">Close</button>
        <button type="button" class="btn btn-primary" (click)="sendmail()" >Send</button>
    </div>
</div>
</ng-template>


<div class="buttoncomposemedia">
    <!--...... <button class="btn btn-compose" data-target="#exampleModal" data-toggle="modal" (click)="composemail()" data-original-title="" title="">
        Compose
    </button> ......-->

    <button class="btn btn-compose" (click)="composemail1(addcmailpopup)">
        Compose
    </button>
    <ul class="inbox-nav inbox-divider">
        <li>
            <a (click)="inbox()"><img src="assets/img/inbox.png" alt="" width="18px"><span>&nbsp;&nbsp;Inbox</span> </a>
        </li>
        <li>
            <a (click)="sentitems()"><i class="fa fa-paper-plane" aria-hidden="true"></i>Sent Mail</a>
        </li>

        <li>
            <a (click)="archiveitems()"><img src="assets/img/download.png" alt="" width="18px"><span>&nbsp;&nbsp;Archive</span></a>
        </li>
        <li>
            <a (click)="deleteitems()"><i class=" fa fa-trash-o" style="font-size: x-large;"></i>Trash</a>
        </li>
        <li>
            <a (click)="gmailallmail()"><i class="fa fa-envelope" style="font-size: x-large;"></i>Gmail-Mail</a>
        </li>

        <li  *ngFor="let aa of Sourceemails">
            <a  (click)="gmailsourcemail(aa)" style="color: #1a0dab;font-style: normal;font-weight: bold;font-size: 14px;">{{aa.googleMail}}</a>
        </li>
        <br>
        <p class="underline-on-hover" *ngIf='!showeditsign' style="font-weight: bold;" (click)="addSign(addsign)">Add Signature</p>
        <p class="underline-on-hover" *ngIf='showeditsign' style="font-weight: bold;" (click)="addSign(addsign)">Edit Signature</p>
        <li>
            <a class="modal-title w-100" id="folderText" *ngIf="install" style="color:blue;font-size: 14px;" href="https://chrome.google.com/webstore/detail/blocdrive/acmkailckjgackliddhdencebmfgoehl/related?hl=en"><b><u>Install Extensions for External Mail</u></b><br>
            </a>
        </li>
        <div class="extensioncomposemedia">

        <li>
            <a class="modal-title w-100" id="folderText" *ngIf="install" style="background-color: white; color: #1a0dab; font-weight: bold;" >
                <span style="color:#4285F4">G</span>
                <span style="color:#DB4437">o</span>
                <span style="color:#F4B400">o</span>
                <span style="color:#4285F4">g</span>
                <span style="color:#0F9D58">l</span>
                <span style="color:#DB4437">e</span>
            </a>
        </li>
        <li>
            <a class="modal-title w-100" id="folderText" *ngIf="install" style="background-color: white; color: #1a0dab; font-weight: bold;" >
                Outlook</a>
        </li>
        <li>
            <a class="modal-title w-100" id="folderText" *ngIf="install" style="background-color: white; color:#720e9e; font-weight: bold;">
                Yahoo</a>
        </li>
    </div>
    </ul>
</div>


<!-- Upload from computer modal -->
<!-- <div class="modal modal-angular fade" id="UploadModalPopup" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog" role="document">
        <div class="modal-content" id="choosepopup">
            <button type="button" class="close" id="closeinto" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true" style="float: right;">&times;</span></button>
            <br />
            <div>
                <p class="modal-title w-100" id="folderText">Attach File</p>
            </div>
            <br />
            <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-8">
        
                </div>
                <div class="col-md-2"></div>
            </div>

            <br />
            <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-8">
                    <div data-dismiss="modal" data-toggle="modal" data-target="#choosefromhaspitmaindiv" id="chosefromcom">
                        <br /> <br />
                        <img data-dismiss="modal" data-toggle="modal" data-target="#choosefromhaspitmaindiv" src="./assets/img/Choosefrom.png" style="cursor: pointer;" />
                        <br />
                        <p class="temp-upload-icon" data-dismiss="modal" style="margin-top: 1%;" data-toggle="modal" data-target="#choosefromhaspitmaindiv">
                            UPLOAD FROM BLOCDRIVE
                        </p>
                        <br />
                    </div>
                </div>
                <div class="col-md-2"></div>
            </div>
            <div class="modal-footer">
                <div class="col-md-12 row">
                    <div class="col-md-5"></div>
                    <div style="margin-left: -6%;">
                        <button type="button" id="testt" class="close" data-dismiss="modal" aria-label="Close"></button>
                        <button type="submit" id="CloseBtn" class="btn btn-success
btn-sm waves-light" mdbWavesEffect data-dismiss="modal" data-toggle="modal">CANCEL</button><br>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div> -->
<!-- choosefrom main haspit modal -->
<!-- choosefrom main haspit modal -->
<div class="modal modal-angular fade" id="choosefromhaspitmaindiv" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true"
          id="closeinto">&times;</span></button>
            <br />
            <div>
                <p class="modal-title w-100" id="folderText">Attach from Blocdrive</p>
            </div>
            <div class="modal-body" style="margin-top: 0%;">
                <div *ngIf="folderdisaply">
                    <div class="row">
                        <div class="col-lg-12" style="overflow-y: auto;height: 60vh;     ">
                            <!-- <p id="homediv">Home</p> -->
                            <!-- <p id="namediv">Name</p> -->
                            <table class="table table-hover" id="tabledivision">
                                <tbody class="items">
                                    <tr id="trdiv">
                                        <td style="cursor: pointer;" data-dismiss="modal" data-toggle="modal" data-target="#choosefromhaspitacc">
                                            <img width="30px" height="30px" src="./assets/img/folder.png" style="cursor: pointer;margin-top: -1%;" />
                                            <span id="spandivv">My Personal Files</span>
                                        </td>
                                    </tr>
                                </tbody>
                                <!-- <tbody class="items">
                  <tr id="trdiv">
                    <td style="cursor: pointer;" data-dismiss="modal"
data-toggle="modal"
                      data-target="#choosefromshared">
                      <img src="./assets/img/folder_icon.png"
style="cursor: pointer;margin-top: -1%;" />
                      <span id="spandivv">Shared with Others</span>
                    </td>
                  </tr>
                </tbody> -->
                                <!-- <tbody class="items">
                  <tr id="trdiv">
                    <td style="cursor: pointer;" data-dismiss="modal"
data-toggle="modal"
                      data-target="#choosefromdeletd">
                      <img src="./assets/img/folder_icon.png"
style="cursor: pointer;margin-top: -1%;" />
                      <span id="spandivv">Deleted Files</span>
                    </td>
                  </tr>
                </tbody> -->
                            </table>
                            <br />
                        </div>
                        <br />
                        <br />
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="col-md-12 row">
                    <div class="col-md-4"></div>
                    <button type="submit" id="uploadBtn" class="btn btn-success
btn-sm waves-light" mdbWavesEffect data-dismiss="modal" data-toggle="modal">UPLOAD</button>
                    <button type="submit" id="CancelBtn" class="btn btn-success
btn-sm waves-light" mdbWavesEffect data-dismiss="modal" data-toggle="modal">CANCEL</button>
                </div>

            </div>
        </div>
        <div>&nbsp;</div>
        <div>&nbsp;</div>
    </div>
</div>

<!-- choosefromhaspit My personal files modal -->

<div class="modal modal-angular fade" id="choosefromhaspitacc" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog" role="document">
        <div class="modal-content" style="    display: block;">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true"
          id="closeinto">&times;</span></button>
            <br />
            <div>
                <p class="modal-title w-100" id="folderText">Upload from Blocdrive</p>
            </div>
            <div class="modal-body">

                <div *ngIf="folderdisaply">
                    <div class="col-md-12 row" id="popupcurrentpagename">
                        <p id="Breadcrumb" (click)="closecurrentpopup()" data-toggle="modal" data-target="#choosefromhaspitmaindiv">Home</p>
                        <span (click)="GetAllFolders()" id="Breadcrumbone" style="cursor: pointer;"> &nbsp; <img
              width="30px" height="30px"
src="./assets/img/folder.png" />&nbsp; My Personal Files</span>
                        <span *ngFor="let d of foldernamelist" id="Breadcrumb">
              &nbsp; <img width="30px" height="30px"
src="./assets/img/folder.png" />&nbsp;
              <span (click)="GetDetails(d)" style="cursor:
pointer;">{{d.folderName}}</span>
                        </span>
                        <!-- <span id="Breadcrumbone" style="cursor: pointer;">
&nbsp; <img src="./assets/img/Vector122.png" />
              &nbsp;{{currentfoldername}}</span> -->
                    </div>
                    <div class="row">
                        <div class="col-lg-12" style="overflow-y: auto;height: 60vh;">
                            <!-- <p id="allfoldershome">Home > <span
id="mypersonalid">My Personal Files</span></p>               -->
                            <table class="table table-hover table-responsive" id="popuptable" style="display: inline-table;">
                                <thead>
                                    <tr id="theadstyle">
                                        <th style="padding-left:6%">Name</th>
                                        <th>Modified</th>
                                    </tr>
                                </thead>
                                <tbody class="items" *ngFor="let folderview of allFolders">
                                    <tr id="trdiv">
                                        <td (click)="GetDetails(folderview)" style="cursor: pointer;" id="foldernamedisplay1">

                                            <div class="col-md-12 row">
                                                <div class="col-md-1">
                                                    <img width="30px" height="30px" src="./assets/img/folder.png" style="cursor: pointer;" />
                                                </div>
                                                <div class="col-md-10" id="datadisplay2" style="cursor: pointer;">
                                                    {{folderview.folderName}}
                                                </div>
                                            </div>
                                        </td>
                                        <td id="datestyle">{{folderview.ModifiedOn | date: 'MM/dd/yy, hh:mm a'}}</td>
                                    </tr>
                                </tbody>
                                <tbody class="items" *ngFor="let ret of allFoldersList">
                                    <tr id="hoverlink" id="{{ret.Id}}" style="height: 60px;">
                                        <td style="cursor: pointer;" *ngIf="ret.DocumentType=='image/jpeg'||ret.DocumentType=='image/png'||ret.DocumentType=='png'
||ret.DocumentType=='jpeg'||ret.DocumentType=='jpg'

||ret.DocumentType=='PNG'" id="foldernamedisplay1">

                                            <div class="col-md-12 row" (click)="selectfile(ret)">
                                                <!-- <div class="col-md-1">
                                                    <img src="./assets/img/uploa_files.png" style="cursor: pointer;height: 26px; " />
                                                </div> -->
                                                <div class="col-md-10" id="datadisplay2" style="cursor: pointer;">
                                                    {{ret.DocumentName}}
                                                </div>
                                            </div>
                                        </td>
                                        <td id="datestyle">{{ret.date | date: 'MM/dd/yy, hh:mm a'}}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <br />
                        </div>
                        <br />
                        <br />
                    </div>
                </div>
                <div *ngIf="subfolderdisaply">
                    <div class="col-md-12 row" id="popupcurrentpagename">
                        <p id="Breadcrumb" (click)="closecurrentpopup()" data-toggle="modal" data-target="#choosefromhaspitmaindiv">Home</p>
                        <span (click)="GetMypersonalfolders()" id="Breadcrumb" style="cursor: pointer;"> &nbsp; <img
                src="./assets/img/Vector122.png" />&nbsp; My Personal
Files</span>
                        <span *ngFor="let d of foldernamelist" id="Breadcrumb">
              &nbsp; <img src="./assets/img/Vector122.png" />&nbsp;
              <span (click)="GetSubfolderdetails(d)" style="cursor:
pointer;">{{d.folderName}}</span>
                        </span>
                        <span id="Breadcrumbone" style="cursor: pointer;"> &nbsp;
<img width="30px" height="30px" src="./assets/img/folder.png" />
              &nbsp;{{currentfoldername}}</span>
                    </div>
                    <div class="row">
                        <div class="col-lg-12" style="overflow-y: auto;height: 60vh;">
                            <!-- <p id="allfoldershome">Home > <span
id="mypersonalid">My Personal Files > {{currentfoldername}}</span></p>
-->
                            <table class="table table-hover table-responsive" style="display: inline-table;" *ngIf="allSubFolders" id="popuptable">
                                <thead>
                                    <tr id="theadstyle">
                                        <th style="padding-left:6%">Name</th>
                                        <th>Modified</th>
                                    </tr>
                                </thead>
                                <tbody class="items" *ngFor="let folderview of
allSubFolders.retData">
                                    <tr id="trdiv">
                                        <td *ngIf="folderview.folderName" style="cursor:
pointer;" (click)="GetSubfolderdetails(folderview)" id="foldernamedisplay1">

                                            <div class="col-md-12 row">
                                                <div class="col-md-1">
                                                    <img width="30px" height="30px" src="./assets/img/folder.png" style="cursor: pointer;" />
                                                </div>
                                                <div class="col-md-10" id="datadisplay3" style="cursor: pointer;">
                                                    {{folderview.folderName}}
                                                </div>
                                            </div>
                                        </td>
                                        <td *ngIf="!folderview.folderName" id="foldernamedisplay1">
                                            <div class="col-md-12 row">
                                                <input type="checkbox" (click)="onClick(folderview)" style="
                        height: 16px;
                        width: 16px;
                        /* margin-top: 28px; */
                        cursor: pointer;
                      " />
                                                <!-- <div class="col-md-1">
                                                    <img src="./assets/img/uploa_files.png" style="cursor: pointer;height: 26px; " />
                                                </div> -->

                                                <div class="col-md-10" id="datadisplay3" style="cursor: pointer;" (click)="onClick(folderview)">
                                                    {{folderview.documentName}}
                                                </div>
                                            </div>
                                        </td>
                                        <td id="datestyle" *ngIf="folderview.folderName">
                                            {{folderview.ModifiedOn | date: 'MM/dd/yy, hh:mm a'}}</td>
                                        <td id="datestyle" *ngIf="!folderview.folderName">{{folderview.date | date: 'MM/dd/yy, hh:mm a'}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <br />
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer" style="margin-right: 10%;">
                <button type="button" id="closetwo" class="close" data-dismiss="modal" aria-label="Close"></button>
                <button type="submit" id="Uploadbtnn" class="btn btn-success
btn-sm waves-light" mdbWavesEffect data-dismiss="modal" data-toggle="modal" (click)="uploadmypersonalfiles()">UPLOAD</button>
                <button type="submit" id="CancelBtn" class="btn btn-success
btn-sm waves-light" mdbWavesEffect data-dismiss="modal" data-toggle="modal">CANCEL</button>
            </div>
        </div>
        <div>&nbsp;</div>
        <div>&nbsp;</div>
    </div>
</div>
<br /> <br /> <br />
<br /> <br /> <br />





<ng-template #choosefromhaspitaccextra let-d="dismiss" let-c="dismiss">
    <div class="aaa">
    <div class="modal-header">        
              <div>
                  <p class="modal-title w-100" id="folderText">Upload From Blocdrive</p>
              </div>
              <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
              </button>
    </div>
    <div class="modal-body">

        <div *ngIf="folderdisaply">
            <div class="col-md-12 row" id="popupcurrentpagename">
                <p id="Breadcrumb" (click)="closecurrentpopup()" data-toggle="modal" data-target="#choosefromhaspitmaindiv">Home</p>
                <span (click)="GetAllFolders()" id="Breadcrumbone" style="cursor: pointer;"> &nbsp; <img
      width="30px" height="30px"
src="./assets/img/folder.png" />&nbsp; My Personal Files</span>
                <span *ngFor="let d of foldernamelist" id="Breadcrumb">
      &nbsp; <img width="30px" height="30px"
src="./assets/img/folder.png" />&nbsp;
      <span (click)="GetDetails(d)" style="cursor:
pointer;">{{d.folderName}}</span>
                </span>
                <!-- <span id="Breadcrumbone" style="cursor: pointer;">
&nbsp; <img src="./assets/img/Vector122.png" />
      &nbsp;{{currentfoldername}}</span> -->
            </div>
            <div class="row">
                <div class="col-lg-12" style="overflow-y: auto;height: 60vh;">
                    <!-- <p id="allfoldershome">Home > <span
id="mypersonalid">My Personal Files</span></p>               -->
                    <table class="table table-hover table-responsive" id="popuptable" style="display: inline-table;">
                        <thead>
                            <tr id="theadstyle">
                                <th style="padding-left:6%">Name</th>
                                <th>Modified</th>
                            </tr>
                        </thead>
                        <tbody class="items" *ngFor="let folderview of allFolders">
                            <tr id="trdiv">
                                <td (click)="GetDetails(folderview)" style="cursor: pointer;" id="foldernamedisplay1">

                                    <div class="col-md-12 row">
                                        <div class="col-md-1">
                                            <img width="30px" height="30px" src="./assets/img/folder.png" style="cursor: pointer;" />
                                        </div>
                                        <div class="col-md-10" id="datadisplay2" style="cursor: pointer;">
                                            {{folderview.folderName}}
                                        </div>
                                    </div>
                                </td>
                                <td id="datestyle">{{folderview.ModifiedOn | date: 'MM/dd/yy, hh:mm a'}}</td>
                            </tr>
                        </tbody>
                        <tbody class="items" *ngFor="let ret of allFoldersList">
                            <tr id="hoverlink" id="{{ret.Id}}" style="height: 60px;">
                                <td style="cursor: pointer;" *ngIf="ret.DocumentType=='image/jpeg'||ret.DocumentType=='image/png'||ret.DocumentType=='png'
||ret.DocumentType=='jpeg'||ret.DocumentType=='jpg'

||ret.DocumentType=='PNG'" id="foldernamedisplay1">

                                    <div class="col-md-12 row" (click)="selectfile(ret)">
                                        <!-- <div class="col-md-1">
                                            <img src="./assets/img/uploa_files.png" style="cursor: pointer;height: 26px; " />
                                        </div> -->
                                        <div class="col-md-10" id="datadisplay2" style="cursor: pointer;">
                                            {{ret.DocumentName}}
                                        </div>
                                    </div>
                                </td>
                                <td id="datestyle">{{ret.date | date: 'MM/dd/yy, hh:mm a'}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <br />
                </div>
                <br />
                <br />
            </div>
        </div>
        <div *ngIf="subfolderdisaply">
            <div class="col-md-12 row" id="popupcurrentpagename">
                <p id="Breadcrumb" (click)="closecurrentpopup()" (click)="attapop1(attachoosefromhaspitmaindivextra)">Home</p>
                <span (click)="GetMypersonalfolders()" id="Breadcrumb" style="cursor: pointer;"> &nbsp; <img
        src="./assets/img/Vector122.png" />&nbsp; My Personal
Files</span>
                <span *ngFor="let d of foldernamelist" id="Breadcrumb">
      &nbsp; <img src="./assets/img/Vector122.png" />&nbsp;
      <span (click)="GetSubfolderdetails(d)" style="cursor:
pointer;">{{d.folderName}}</span>
                </span>
                <span id="Breadcrumbone" style="cursor: pointer;"> &nbsp;
<img width="30px" height="30px" src="./assets/img/folder.png" />
      &nbsp;{{currentfoldername}}</span>
            </div>
            <div class="row">
                <div class="col-lg-12" style="overflow-y: auto;height: 60vh;">
                    <!-- <p id="allfoldershome">Home > <span
id="mypersonalid">My Personal Files > {{currentfoldername}}</span></p>
-->
                    <table class="table table-hover table-responsive" style="display: inline-table;" *ngIf="allSubFolders" id="popuptable">
                        <thead>
                            <tr id="theadstyle">
                                <th style="padding-left:6%">Name</th>
                                <th>Modified</th>
                            </tr>
                        </thead>
                        <tbody class="items" *ngFor="let folderview of
allSubFolders.retData">
                            <tr id="trdiv">
                                <td *ngIf="folderview.folderName" style="cursor:
pointer;" (click)="GetSubfolderdetails(folderview)" id="foldernamedisplay1">

                                    <div class="col-md-12 row">
                                        <div class="col-md-1">
                                            <img width="30px" height="30px" src="./assets/img/folder.png" style="cursor: pointer;" />
                                        </div>
                                        <div class="col-md-10" id="datadisplay3" style="cursor: pointer;">
                                            {{folderview.folderName}}
                                        </div>
                                    </div>
                                </td>
                                <td *ngIf="!folderview.folderName" id="foldernamedisplay1">
                                    <div class="col-md-12 row">
                                        <input type="checkbox" (click)="onClick(folderview)" style="
                height: 16px;
                width: 16px;
                /* margin-top: 28px; */
                cursor: pointer;
              " />
                                        <!-- <div class="col-md-1">
                                            <img src="./assets/img/uploa_files.png" style="cursor: pointer;height: 26px; " />
                                        </div> -->

                                        <div class="col-md-10" id="datadisplay3" style="cursor: pointer;" (click)="onClick(folderview)">
                                            {{folderview.documentName}}
                                        </div>
                                    </div>
                                </td>
                                <td id="datestyle" *ngIf="folderview.folderName">
                                    {{folderview.ModifiedOn | date: 'MM/dd/yy, hh:mm a'}}</td>
                                <td id="datestyle" *ngIf="!folderview.folderName">{{folderview.date | date: 'MM/dd/yy, hh:mm a'}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <br />
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer" style="margin-right: 10%;">
        <button type="button" id="closetwo" class="close" data-dismiss="modal" aria-label="Close"></button>
        <button type="submit"  id="Uploadbtnn" class="btn btn-success
btn-sm waves-light" (click)="uploadmypersonalfiles()">UPLOAD</button>
        <!-- <button type="submit" id="CancelBtn" class="btn btn-success
btn-sm waves-light" (click)="d('Cross click')">CANCEL</button> -->
    </div>
</div>
</ng-template>



<ng-template #attachoosefromhaspitmaindivextra let-d="dismiss" let-c="dismiss">
    <div class="aaa">
    <div class="modal-header">        
             
              <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
              </button>
                  <div>
                    <p class="modal-title w-100" id="folderText">Upload From Blocdrive</p>
                </div>
    </div>
    <div class="modal-body" style="margin-top: 0%;">
        <div *ngIf="folderdisaply">
            <div class="row">
                <div class="col-lg-12" style="overflow-y: auto;height: 60vh;     ">
                    <!-- <p id="homediv">Home</p> -->
                    <!-- <p id="namediv">Name</p> -->
                    <table class="table table-hover" id="tabledivision">
                        <tbody class="items">
                            <tr id="trdiv">
                                <td style="cursor: pointer;" (click)="attapop(choosefromhaspitaccextra)">
                                    <img width="30px" height="30px" src="./assets/img/folder.png" style="cursor: pointer;margin-top: -1%;" />
                                    <span id="spandivv">My Personal Files</span>
                                </td>
                            </tr>
                        </tbody>
                        <!-- <tbody class="items">
          <tr id="trdiv">
            <td style="cursor: pointer;" data-dismiss="modal"
data-toggle="modal"
              data-target="#choosefromshared">
              <img src="./assets/img/folder_icon.png"
style="cursor: pointer;margin-top: -1%;" />
              <span id="spandivv">Shared with Others</span>
            </td>
          </tr>
        </tbody> -->
                        <!-- <tbody class="items">
          <tr id="trdiv">
            <td style="cursor: pointer;" data-dismiss="modal"
data-toggle="modal"
              data-target="#choosefromdeletd">
              <img src="./assets/img/folder_icon.png"
style="cursor: pointer;margin-top: -1%;" />
              <span id="spandivv">Deleted Files</span>
            </td>
          </tr>
        </tbody> -->
                    </table>
                    <br />
                </div>
                <br />
                <br />
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="col-md-12 row">
            <div class="col-md-4"></div>
            <button type="submit" id="uploadBtn" class="btn btn-success
btn-sm waves-light" mdbWavesEffect data-dismiss="modal" data-toggle="modal" >UPLOAD</button>
            <!-- <button type="submit" id="CancelBtn" class="btn btn-success
btn-sm waves-light" mdbWavesEffect data-dismiss="modal" data-toggle="modal">CANCEL</button> -->
        </div>

    </div>
</div>
    <br />
</ng-template>

<ng-template #addsign let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" *ngIf='!showeditsign' id="modal-basic-title">Add Signature</h4>
        <h4 class="modal-title" *ngIf='showeditsign' id="modal-basic-title">Edit Signature</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body">
        <h5 class="modal-title" *ngIf='!showeditsign' id="modal-basic-title">Add Signature:</h5>
        <h5 class="modal-title" *ngIf='showeditsign' id="modal-basic-title">Edit Signature:</h5>
        <textarea type="text" class="form-control" placeholder="Message" name="signdata" [(ngModel)]="signdata" style="font-size: 20px;line-height: 1.7rem;" rows="4" cols="50"> </textarea>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-success" *ngIf='!showeditsign' (click)="addsignature()" (click)="d('Cross click')" style="cursor: pointer">
      Save
    </button>
    <button type="button" class="btn btn-success" *ngIf='showeditsign' (click)="editsignature()" (click)="d('Cross click')" style="cursor: pointer">
        Update
      </button>
    <span style="color: white;margin-top: 6px;">
        <b style="text-transform: capitalize;font-size: 14px;"> {{notify}}</b></span>
    </div>
</ng-template>


<ng-template #addgmail let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Migrate emails from Gmail</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" *ngIf="isLoggedIn">
              <span aria-hidden="true">&times;</span>
          </button>
    </div>
    <div class="modal-body">

        <h5 class="modal-title" id="modal-basic-title"> Enter the Email:</h5>
        <input type="text" id="mail" [(ngModel)]="mail" class="form-control" name="dp" />
    </div>
    <br />
    <div class="modal-footer">
        <button type="button" class="btn btn-success" (click)="savegmail()" (click)="d('Cross click')" style="cursor: pointer;">
              Migrate Gmail
          </button>
    </div>
    <br />
</ng-template>
