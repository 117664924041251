import { Component, OnInit } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuditorTransactionService } from '../Shared/auditortransaction/auditor-transaction.service';
import { DepartmentService } from '../Shared/Department/department.service';
import { DesignationService } from '../Shared/Designation/designation.service';
import { EncryptionService } from '../Shared/encryption/encryption.service';
import { RoleService } from '../Shared/Role/role.service';
import { TaskService } from '../Shared/Task/task.service';
import { UserDetailsService } from '../Shared/UserDetails/user-details.service';
import { teams } from '../ViewModels/Designation';


@Component({
  selector: 'app-project-teams',
  templateUrl: './project-teams.component.html',
  styleUrls: ['./project-teams.component.css']
})
export class ProjectTeamsComponent implements OnInit {

    closeResult: any;
    teamname: any;
  
    TeamlistDesc: any;
    Teamlistdesc: any;
    public dismissalert = false;
    notify: string;
    Teamlist: any;
    p: number = 1;
    editteamname: any;
    editdesc: any;
    editTeamlistid: any;
    uteamname: any;
    uTeamlistdesc: any;
    createdon: any;
    createdby: any;
    DeleteName: any;
    deleteid: any;
    RoleList: any = [];
    DepartmentList: any = [];
    selectedrole: any;
    selecteddepartment: any;
    editrole: any;
    editdepid: any;
    AllDepartmentList: any = [];
    uid: string;
    roleid: string;
    mobileview: boolean;
    GUID: string;
    userList: any;
    projectlist: any;
    selecteduserid: any;
    projectid: number;
    showUpdate: boolean;
    showSave: boolean;
    responsibility: any;
    teamid: any;
    ProjectList: any;
  selectedItems: any;
  uids: any = [];
  proname: any;
  usernames: any=[];
  dropdownSettings: IDropdownSettings;

    constructor(private modalService: NgbModal, private auditortransactionservice: AuditorTransactionService
      , private taskservice: TaskService, private spinner: NgxSpinnerService, private designationService: DesignationService, private userservice: UserDetailsService,
      private roleService: RoleService, private departmentService: DepartmentService,private encryptionService:EncryptionService) {
      if (localStorage.getItem("IsLoggedIn") == "true") {
        this.uid = localStorage.getItem("userId");
        this.roleid = localStorage.getItem("role");
        this.GUID = localStorage.getItem("GUID");
  
      }
      this.isMobileMenu();
    }
  
    ngOnInit(): void {
      
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'assignRigntType',
      selectAllText: 'Select Users',
      unSelectAllText: 'Selected Users',
      itemsShowLimit: 100,
      allowSearchFilter: true,
      searchPlaceholderText: 'Search for Users',
      noDataAvailablePlaceholderText: 'No data available',
    }
      this.Teamlists();
      this.Teamlistdata();
      this.taskassignnewuserList();
      this.ProjectName();
      this.Roles();
      this.Departments();
      this.teamname = "";
      this.TeamlistDesc = "";
    }
    isMobileMenu() {
      if ($(window).width() > 991) {
        this.mobileview = false;
      }
      else {
        this.mobileview = true;
      }
    };
    taskassignnewuserList() {
      this.userservice.getAllUsers(this.GUID).subscribe((Data: any) => {
        const decryptedData = this.encryptionService.decryptData(Data);
      this.userList = decryptedData;
        //this.userList = Data;
        //......
        for (var i = 0; i < this.userList.length; i++) {
          this.usernames.push(this.userList[i].userName)
        }
      })
    }
    onSelectUser(id) {
      this.selecteduserid = id;
    }
    Roles() {
      this.spinner.show();
      this.roleService.getAllRoles(this.GUID).subscribe((Data: any) => {
        
        this.RoleList = Data;
        this.spinner.hide();
      });
    }
    onSelectedProject(Id: number) {
      this.projectid = Id;
    }
  
    addClick() {
      this.showSave = true;
      this.showUpdate = false;
  
    }
    Departments() {
      this.spinner.show();
      this.departmentService.getAllDepartments(this.GUID).subscribe((Data: any) => {
        this.AllDepartmentList = Data;
        this.spinner.hide();
      });
    }
    Teamlistdata() {
      this.spinner.show();
      this.designationService.getteams(this.GUID).subscribe((Data: any) => {
        const decryptedData = this.encryptionService.decryptData(Data);
      this.Teamlist = decryptedData;
        //this.Teamlist = Data;
        this.spinner.hide();
      });
    }
    ProjectName() {
      this.spinner.show();
      this.taskservice.getTeamProject(this.GUID).subscribe((response: any) => {
        const decryptedData = this.encryptionService.decryptData(response);
      this.ProjectList = decryptedData;
        //this.ProjectList = response;
      })
    }
  
    GetProjectName(id){
      for (let i = 0; i <  this.ProjectList.length; i++) {
      if(id==this.ProjectList[i].uniqueProjectId){
        return this.ProjectList[i].projectName;
      }
        
      }
    }
    Teamlists() {
      this.spinner.show();
      this.designationService.getteams(this.GUID).subscribe((Data: any) => {
        const decryptedData = this.encryptionService.decryptData(Data);
      this.Teamlist = decryptedData;
        //this.Teamlist = Data;
        this.spinner.hide();
      });
    }
  
    EditTeamlist(updatedata, updateTeamlist) {
      //......
      for (let i = 0; i < this.ProjectList.length; i++) {
        if (updatedata.project == this.ProjectList[i].uniqueProjectId) {
         this.proname = this.ProjectList[i].projectName;
        }
  
      }
      this.projectid = updatedata.project;
      this.teamname = updatedata.team;
      this.teamid = updatedata.id;
      this.responsibility = updatedata.responsibility;
      this.editrole = updatedata.roleId;
      this.editdepid = updatedata.departmentId
      this.modalService.open(updateTeamlist).result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    }
    addTeamlist(adTeamlist) {
      this.modalService.open(adTeamlist).result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    }
    DeleteTeamlist(deletedata, addTeamlist) {
      this.DeleteName = deletedata.teamname;
      this.deleteid = deletedata.id;
      this.modalService.open(addTeamlist).result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    }
    private getDismissReason(reason: any): string {
      if (reason === ModalDismissReasons.ESC) {
        return "by pressing ESC";
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return "by clicking on a backdrop";
      } else {
        return `with: ${reason}`;
      }
    }
    SaveTeamlist() {
      for (var i = 0; i < this.selectedItems.length; i++) {
        for (var j = 0; j < this.userList.length; j++) {
          if (this.selectedItems[i] == this.userList[j].userName) {
            this.uids.push(this.userList[j])
          }
        }
      }
      const inputRequest: teams = {
        Id: 0,
        UserId : this.uid,
        GUID:this.GUID,
        Team: this.teamname,
        Project: this.projectid,
        Responsibility:this.responsibility ,
//......  Employee: this.selecteduserid,
        Employee: this.uids,
        CreatedOn: null,
  
      };
      this.spinner.show();
      this.designationService.saveteam(inputRequest)
        .subscribe((data: any) => {
          if (data == "success") {
            this.notify = "Teamlist added successfully";
            const audit = {
              TransactionName: "Master",
              TransactionType: "Create",
              TransactionDesc: "Teamlist master",
  
            }
            this.auditortransactionservice.AuditorTransactionDetails(audit);
            setTimeout(() => (this.dismissalert = true), 100);
            setTimeout(
              function () {
                this.dismissalert = false;
              }.bind(this),
              3000
            );
            this.spinner.hide();
            this.Teamlists();
          } else {
            this.notify = "Something Went Wrong. Try again.!!";
            setTimeout(() => (this.dismissalert = true), 100);
            setTimeout(
              function () {
                this.dismissalert = false;
              }.bind(this),
              3000
            );
            this.spinner.hide();
            this.Teamlists();
          }
        });
    }
  
    UpdateTeamlist() {
  //......
  if(this.selectedItems == undefined){
    alert("Please select employees to update the teamlist")
  }
  else if(this.selectedItems.length != null){
      for (var i = 0; i < this.selectedItems.length; i++) {
        for (var j = 0; j < this.userList.length; j++) {
          if (this.selectedItems[i] == this.userList[j].userName) {
            this.uids.push(this.userList[j])
          }
        }
        //to remove the duplicates from an array
        this.uids= Array.from(new Set(this.uids));
      }
      const inputRequest1: teams = {
        Id: this.teamid,
        UserId : this.uid,
        GUID:this.GUID,
        Team: this.teamname,
        Project: this.projectid,
        Responsibility: this.responsibility,
        //......  Employee: this.selecteduserid,
        Employee: this.uids,
        CreatedOn: null,
      };
      this.spinner.show();
      this.designationService.Updateteams(inputRequest1).subscribe((data: any) => {
          if (data == "success") {
            this.notify = "Teamlist updated successfully";
            const audit = {
              TransactionName: "Master",
              TransactionType: "Update",
              TransactionDesc: "Teamlist master",
  
            }
            this.auditortransactionservice.AuditorTransactionDetails(audit);
            setTimeout(() => (this.dismissalert = true), 100);
            setTimeout(
              function () {
                this.dismissalert = false;
              }.bind(this),
              3000
            );
            this.Teamlists();
            this.spinner.hide();
          } else {
            this.notify = "Something Went Wrong. Try again.!!";
            setTimeout(() => (this.dismissalert = true), 100);
            setTimeout(
              function () {
                this.dismissalert = false;
              }.bind(this),
              3000
            );
            this.Teamlists();
            this.spinner.hide();
          }
        });
      }
    }
    
    DeleteTeamlistRow() {
      this.spinner.show();
      this.designationService
        .deleteteams(this.deleteid)
        .subscribe((data: any) => {
          if (data == "success") {
            this.notify = "Teamlist deleted successfully";
            const audit = {
              TransactionName: "Master",
              TransactionType: "Delete",
              TransactionDesc: "Teamlist master",
  
            }
            this.auditortransactionservice.AuditorTransactionDetails(audit);
            setTimeout(() => (this.dismissalert = true), 100);
            setTimeout(
              function () {
                this.dismissalert = false;
              }.bind(this),
              3000
            );
            this.Teamlists();
            this.spinner.hide();
          } else {
            this.notify = "Something Went Wrong. Try again.!!";
            setTimeout(() => (this.dismissalert = true), 100);
            setTimeout(
              function () {
                this.dismissalert = false;
              }.bind(this),
              3000
            );
            this.Teamlists();
            this.spinner.hide();
          }
        });
    }
    closealert() {
      this.dismissalert = false;
    }
    GetDepartment(deptid) {
      for (let i = 0; i < this.AllDepartmentList.length; i++) {
        if (deptid == this.AllDepartmentList[i].id) {
          return this.AllDepartmentList[i].majorDepartmentName;
        }
      }
    }
    GetRole(rid) {
      for (let i = 0; i < this.RoleList.length; i++) {
        if (rid == this.RoleList[i].id) {
          return this.RoleList[i].roleName;
        }
      }
    }
    onItemSelect(item: any) {
      var aa = this.selectedItems;
    }
    onSelectAll(items: any) {
      var aa = this.selectedItems;
    }
    onItemDeSelectAll(items: any) {
      var aa = this.selectedItems;
    }
    onItemDeSelect(items) {
      var aa = this.selectedItems;
    }
  }