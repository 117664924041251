import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { EncryptionService } from 'src/app/Shared/encryption/encryption.service';
import { MailService } from 'src/app/Shared/Mail/mail.service';
import { MovesService } from 'src/app/Shared/MoveService/moves.service';
import { UserDetailsService } from 'src/app/Shared/UserDetails/user-details.service';
import { MailboxVM } from 'src/app/ViewModels/Email';

@Component({
  selector: 'app-inbox-mails',
  templateUrl: './inbox-mails.component.html',
  styleUrls: ['./inbox-mails.component.css']
})
export class InboxMailsComponent implements OnInit {
  public isLoggedIn: boolean = false;
  uid: string;
  roleid: string;
  userName: string;
  usermail: string;
  pid: string;
  gridvalue: string;
  GUID: string;
  DateTime: Date;
  InboxList: any;
  UsersList: any;
  notify: string;
  public n: any = 20; num: any; 
  selectedSelected: any = [];
  showper: boolean;
  generalprogressbar: boolean;
  folderCreating: boolean;
  q: number = 1;
  p: number = 1;
  searchText: any;
  CurrentFiles: any;
  createdmsg: string;
  InboxList1: any = [];
  showcheckbox: boolean;
  showdel: boolean;
  InboxList2: any=[];
  inboxm: string;
  showinb: string;
  constructor(private router: ActivatedRoute, private userservice: UserDetailsService, private moveService: MovesService,
    private route: Router, private httpService: HttpClient, private modalService: NgbModal,
    private spinner: NgxSpinnerService, private datepipe: DatePipe,
     private http: HttpClient, private mailservice: MailService,private encryptionService:EncryptionService) {
    if (localStorage.getItem("IsLoggedIn") == "true") {
      this.isLoggedIn = true;
      this.uid = localStorage.getItem("userId");
      this.roleid = localStorage.getItem("role");
      this.userName = localStorage.getItem("UserName");
      this.pid = localStorage.getItem("parentid");
      this.usermail = localStorage.getItem("MailId");
      this.gridvalue = localStorage.getItem("gridvalue");
      this.GUID = localStorage.getItem("GUID");
      this.showinb = localStorage.getItem("Showinbox");
      if(this.showinb == "True"){
        window.location.reload();
      }
      if(this.showinb != null){
      localStorage.removeItem("Showinbox");
      console.log(this.showinb);
      }
    }
  }

  ngOnInit(): void {
    this.GetInboxList();
    this.GetUsers();
  }
  	//checkAllCheckBox(ev) { // Angular 9
    checkAllCheckBox(ev: any) { // Angular 13
      this.InboxList.forEach(x => x.checked = ev.target.checked)
    
    }
  
    isAllCheckBoxChecked() {
      return this.InboxList.every(a => a.checked);
    }
    Deletecheck(){
      for (let i = 0; i < this.InboxList.length; i++) {
        if (this.InboxList[i].checked == true) {
          this.InboxList1.push(this.InboxList[i]);
        }
        
      }
      var info ={
        Fromuser  :this.uid,
        MailsList: this.InboxList1
      }
      this.mailservice.deletechecklist(info).subscribe((respose: any) => {
        if(respose == "1"){
          this.notify = "Deleted selected items Successfully...!"
        this.GetInboxList();
        }
       
      });
    }

    archiveselected(){
      for (let i = 0; i < this.InboxList.length; i++) {
        if (this.InboxList[i].checked == true) {
          this.InboxList2.push(this.InboxList[i]);
        }
        
      }
      var info ={
        Fromuser  :this.uid,
        MailsList: this.InboxList2
      }
      this.mailservice.archiveselect(info).subscribe((respose: any) => {
        if(respose == "1"){
          this.notify = "Deleted selected items Successfully...!"
        this.GetInboxList();
        }
       
      });
    }



  GetInboxList() {
    this.spinner.show();
    var info ={
      UID  :this.uid
    }
    this.mailservice.getInboxlist(info).subscribe((respose: any) => {
      const decryptedData = this.encryptionService.decryptData(respose);
      this.InboxList = decryptedData;
      //this.InboxList = respose;
      if(this.InboxList != 0){
        this.showcheckbox = true;
       }
      var res = this.InboxList.sort((a,b)=> b.epochtime-a.epochtime)
      //console.log(res);
      //console.log(respose);
      this.spinner.hide(); 
    });
  }

  GetUsers() {
    this.spinner.show();
    this.mailservice.getUsers(this.uid).subscribe((respose: any) => {
      const decryptedData = this.encryptionService.decryptData(respose);
      this.UsersList = decryptedData;
      //this.UsersList = respose;
      this.spinner.hide();
    });
  }
  inbox() {
    this.route.navigate(['inboxmails']);
  }
  composemail() {
    this.route.navigate(['composemsg']);
  }
  deleteitems() {
    this.route.navigate(['del']);
  }
  sentitems() {
    this.route.navigate(['sentmails']);
  }
  archiveitems() {
    this.route.navigate(['archive']);
  }
  ReadMessage(data) {

    localStorage.setItem('fromuser', data.From);
    localStorage.setItem('touser', data.To);
    localStorage.setItem('attachments', data.Attachments);
    localStorage.setItem('mailid', data.MasId);
    localStorage.setItem('transid', data.TransId);
    localStorage.setItem('maildate', data.DateforRM);
    localStorage.setItem('profilepic', data.UserProfilePic);
    localStorage.setItem('firstletter', data.FirstLetter);
    localStorage.setItem('docname', data.DocumentName);
    localStorage.setItem('emailbody', data.EmailBody);
    localStorage.setItem('sub', data.Subject); 
    this.spinner.show();
    var info ={
      UID  :data.mailId
    }
    this.mailservice.postupdatemailread(info).subscribe((respose: any) => {
      var aa = respose;
      this.spinner.hide();
      this.inboxm = 'inbox-' + data.mailId;
      this.route.navigate(['readmsg/'+ this.inboxm ]);
    });
   
  }

  reply(data) {
    localStorage.setItem('fromuser', data.From);
    localStorage.setItem('touser', data.To);
    localStorage.setItem('attachments', data.Attachments);
    localStorage.setItem('mailid', data.MasId);
    localStorage.setItem('transid', data.TransId);
    localStorage.setItem('maildate', data.DateforRM);
    localStorage.setItem('profilepic', data.UserProfilePic);
    localStorage.setItem('firstletter', data.FirstLetter);
    localStorage.setItem('docname', data.DocumentName);
    localStorage.setItem('emailbody', data.EmailBody);
    localStorage.setItem('sub', data.Subject);
    localStorage.setItem('rep', "replay");
    this.route.navigate(['readmsg']);
  }


  replyall(data) {
    localStorage.setItem('fromuser', data.From);
    localStorage.setItem('touser', data.To);
    localStorage.setItem('attachments', data.Attachments);
    localStorage.setItem('mailid', data.MasId);
    localStorage.setItem('transid', data.TransId);
    localStorage.setItem('maildate', data.DateforRM);
    localStorage.setItem('profilepic', data.UserProfilePic);
    localStorage.setItem('firstletter', data.FirstLetter);
    localStorage.setItem('docname', data.DocumentName);
    localStorage.setItem('emailbody', data.EmailBody);
    localStorage.setItem('sub', data.Subject);
    localStorage.setItem('rep', "replyall");
    this.route.navigate(['readmsg']);
  }
  singlearchive(data){
    this.selectedSelected.push(data);
    this.multiarchive();
    // var info = {
    //   MailId : data.mailId,
    //   Id: data.id,

    // }
    // this.spinner.show();
    // this.mailservice.postmakeitArchive(info).subscribe((data: any) => {
    //  // this.UsersList = respose;
    //  if (data == "success") {
    //     this.notify = 'Archived Successfully';

    //   this.spinner.hide();
    //   this.GetInboxList();
    //   }
    // });
  }
  
  singleDelete(data){
    this.selectedSelected.push(data);
    this.multidelete();
  }
  singleread(data){
    // this.selectedSelected.push(data);
    // this.multiread();
  }
  
  singleunread(data){
    // this.selectedSelected.push(data);
    // this.multiunread();
  }
  multiread() {
    // this.DateTime = new Date();
    // let latest_date = this.datepipe.transform(this.DateTime, 'dd-MM-yyyy hh:mm:ss a');

    // this.showper = true;
    // this.generalprogressbar = true;
    // this.folderCreating = true;
    // this.getPercent(this.n);
    // this.Creatingmsg = "Reading Messages...";
    // this.num = setInterval(() => {
    //   if (this.n <= 90) {
    //     this.Creatingmsg = "Reading Messages...";
    //     this.n = this.n + this.getPercent(this.n);
    //     if (this.n == 90) {
    //       this.n = 90;
    //       clearInterval(this.num);
    //     }
    //   }
    // }, 3000);

    // const inputRequest1: MailboxVM = {
    //   Fromuser:this.uid,
    //   MailsList:this.selectedSelected,
    //   CurrentDate:latest_date
    // }
    // this.mailboxservice.multireadmessages(inputRequest1).subscribe((respose: any) => {
    //   if(respose == "1"){
    //       this.n=100;
    //       this.createdmsg = "Read Successfully...!!"
    //       this.folderCreating = false;
    //       this.foldercreated = true;
    //       this.selectedSelected = [];
    //       this.morefiles = false;
    //       clearInterval(this.num);

    //       setTimeout(function () {
    //         this.folderCreating = false;
    //         this.foldercreated = false;
    //         this.generalprogressbar = false;
    //         this.errormsg = false;
    //         this.showper = false;
    //         this.n=10;
    //       }.bind(this), 3000);
    //       this.GetInboxList();
    //   }
    //   else{
    //     this.selectedSelected = [];
    //     this.GetInboxList();
    //     this.morefiles = false;
    //   }
    // });
  }

  multiunread() {
    // this.DateTime = new Date();
    // let latest_date = this.datepipe.transform(this.DateTime, 'dd-MM-yyyy hh:mm:ss a');


    // this.showper = true;
    // this.generalprogressbar = true;
    // this.folderCreating = true;
    // this.getPercent(this.n);
    // this.Creatingmsg = "Unreading Messages...";
    // this.num = setInterval(() => {
    //   if (this.n <= 90) {
    //     this.Creatingmsg = "Unreading Messages...";
    //     this.n = this.n + this.getPercent(this.n);
    //     if (this.n == 90) {
    //       this.n = 90;
    //       clearInterval(this.num);
    //     }
    //   }
    // }, 3000);

    // const inputRequest1: MailboxVM = {
    //   Fromuser:this.uid,
    //   MailsList:this.selectedSelected,
    //   CurrentDate:latest_date
    // }
    // this.mailboxservice.multiunreadmessages(inputRequest1).subscribe((respose: any) => {
    //   if(respose == "1"){
    //     this.n=100;
    //     this.createdmsg = "UnRead Successfully...!"
    //     this.folderCreating = false;
    //     this.foldercreated = true;
    //     this.selectedSelected = [];
    //     this.morefiles = false;
    //     clearInterval(this.num);

    //     setTimeout(function () {
    //       this.folderCreating = false;
    //       this.foldercreated = false;
    //       this.generalprogressbar = false;
    //       this.errormsg = false;
    //       this.showper = false;
    //       this.n=10;
    //     }.bind(this), 3000);
    //     this.GetInboxList();
    // }
    // else{
    //   this.selectedSelected = [];
    //   this.GetInboxList();
    //   this.morefiles = false;
    // }
    // });
  }

  deleteallmails(){

    var info ={
      Fromuser  :this.uid,
      MailsList: this.InboxList
    }
    this.mailservice.deleteallmessages(info).subscribe((respose: any) => {
      if(respose == "1"){
        this.n=100;
        this.notify = "Deleted Successfully...!"
        this.GetInboxList();
        this.folderCreating = false;
        this.selectedSelected = [];
    
        clearInterval(this.num);

        setTimeout(function () {
          this.folderCreating = false;
          this.foldercreated = false;
          this.generalprogressbar = false;
          this.errormsg = false;
          this.showper = false;
          this.n=10;
        }.bind(this), 3000);
        this.GetInboxList();
    }
    else{
      this.selectedSelected = [];
      this.GetInboxList();
 
    }
    });

  }
  multiarchive() {
    this.DateTime = new Date();
    let latest_date = this.datepipe.transform(this.DateTime, 'dd-MM-yyyy hh:mm:ss a');


    this.showper = true;
    this.generalprogressbar = true;
    this.folderCreating = true;
    this.getPercent(this.n);
    this.num = setInterval(() => {
      if (this.n <= 90) {
        this.n = this.n + this.getPercent(this.n);
        if (this.n == 90) {
          this.n = 90;
          clearInterval(this.num);
        }
      }
    }, 3000);

    const inputRequest1: MailboxVM = {
      Fromuser:this.uid,
      MailsList:this.selectedSelected,
      CurrentDate:latest_date
    }
    this.mailservice.postmakeitArchive(inputRequest1).subscribe((respose: any) => {
      if(respose == "1"){
        this.n=100;
        this.createdmsg = "Archived Successfully...!"
        this.folderCreating = false;
        this.selectedSelected = [];
        clearInterval(this.num);

        setTimeout(function () {
          this.folderCreating = false;
          this.foldercreated = false;
          this.generalprogressbar = false;
          this.errormsg = false;
          this.showper = false;
          this.n=10;
        }.bind(this), 3000);
        this.GetInboxList();
    }
    else{
      this.selectedSelected = [];
      this.GetInboxList();
    }
    });
  }
  getPercent(per) {
    return 10;
  }
  toggleSelect(data) {
    this.CurrentFiles = data;
    if (data != null) {
      if (this.selectedSelected.length == 0) {
        this.selectedSelected.push(data);
      }
      else {
        const index = this.selectedSelected.indexOf(data, 0);
        if (index > -1) {
          this.selectedSelected.splice(index, 1);
        }
        else {
          this.selectedSelected.push(data); 
        }
      }
    }

    // for (var i = 0; i < this.selectedSelected.length; i++) {
    //   if (this.selectedSelected[i].folderName != null) {
    //     this.sfolder = this.sfolder + 1;
    //   }
    //   else {
    //     this.sfiles = this.sfiles + 1;
    //   }
    // }


  
  }
  multidelete() {
    this.DateTime = new Date();
    let latest_date = this.datepipe.transform(this.DateTime, 'dd-MM-yyyy hh:mm:ss a');

    this.showper = true;
    this.generalprogressbar = true;
    this.folderCreating = true;
    this.getPercent(this.n);
    this.notify = "Deleting Messages...";
    this.num = setInterval(() => {
      if (this.n <= 90) {
        this.notify = "Deleting Messages...";
        this.n = this.n + this.getPercent(this.n);
        if (this.n == 90) {
          this.n = 90;
          clearInterval(this.num);
        }
      }
    }, 3000);

    const inputRequest1: MailboxVM = {
      Fromuser:this.uid,
      MailsList:this.selectedSelected,
      CurrentDate:latest_date
    }
    this.mailservice.multideletemessages(inputRequest1).subscribe((respose: any) => {
      if(respose == "1"){
        this.n=100;
        this.notify = "Deleted Successfully...!"
        this.GetInboxList();
        this.folderCreating = false;
        this.selectedSelected = [];
    
        clearInterval(this.num);

        setTimeout(function () {
          this.folderCreating = false;
          this.foldercreated = false;
          this.generalprogressbar = false;
          this.errormsg = false;
          this.showper = false;
          this.n=10;
        }.bind(this), 3000);
        this.GetInboxList();
    }
    else{
      this.selectedSelected = [];
      this.GetInboxList();
 
    }
    });
  }

  sort = "1";

  sortby() {
    if (this.sort == "1") {
      this.InboxList.sort((a, b) => a.fromName.toLowerCase() < b.fromName.toLowerCase() ? -1 : a.fromName.toLowerCase() > b.fromName.toLowerCase() ? 1 : 0)
      this.sort = "2";
    }
    else if (this.sort == "2") {
      this.InboxList.sort((a, b) => a.fromName.toLowerCase() > b.fromName.toLowerCase() ? -1 : a.fromName.toLowerCase() < b.fromName.toLowerCase() ? 1 : 0)
      this.sort = "1";

    }

  }
  sortname = "1";
  sortbydate() {
    if (this.sortname == "1") {
      this.InboxList.sort((a, b) => a.Sortdate < b.Sortdate ? -1 : a.Sortdate > b.Sortdate ? 1 : 0)
      this.sortname = "2";
    }
    else if (this.sortname == "2") {
      this.InboxList.sort((a, b) => a.Sortdate > b.Sortdate ? -1 : a.Sortdate < b.Sortdate ? 1 : 0)
      this.sortname = "1";

    }

  }

}
