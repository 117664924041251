import { DatePipe } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { visibility } from 'html2canvas/dist/types/css/property-descriptors/visibility';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuditorTransactionService } from 'src/app/Shared/auditortransaction/auditor-transaction.service';
import { GroupService } from 'src/app/Shared/Group/group.service';
import { MailService } from 'src/app/Shared/Mail/mail.service';
import { MovesService } from 'src/app/Shared/MoveService/moves.service';
import { UserDetailsService } from 'src/app/Shared/UserDetails/user-details.service';
import { Friend } from 'src/app/ViewModels/Friend';
import { ShareIdShow } from 'src/app/ViewModels/shareidshow';
import { Location } from '@angular/common';
import { ChangeDetectorRef } from '@angular/core';
import { EncryptionService } from 'src/app/Shared/encryption/encryption.service';
@Component({
  selector: 'app-read-mail',
  templateUrl: './read-mail.component.html',
  styleUrls: ['./read-mail.component.css']
})
export class ReadMailComponent implements OnInit {
  public isLoggedIn: boolean = false;
  uid: string;
  roleid: string;
  userName: string;
  usermail: string;
  pid: string;
  gridvalue: string;
  GUID: string;
  mailid: any;
  maildata: any;
  docments: boolean = false;
  Doc: any;
  imgdisplay: any;
  seleteddownload: boolean;
  closeResult: any;
  msg: any; m: string;
  date: any;
  zzz: any;
  individualmail:any;
  current_url: any; displayUrl: any;
  current_url1: any;
  ID: any; pdfUrl: any; pdfUrl1: any; pdfUrl2: any; pdfUrl3: any;
  videoplay: any;
  videodate: any;
  audioplay: any;
  audiodate: any;
  memlist: any;
  documentsArray:any = []
  tomem: any;
  replytouser: any;
  replayenable: boolean = false;
  mailsubject: any;
  message: any;
  frmDataa: FormData;
  SelectedFiles: any = [];
  selectedFriend: any = [];
  Friends: any;
  frndslist: any;
  Transid: void;
  errorMessages: string;
  email: any = [];
  serchfind: any;
  regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  frndslistone: any;
  accid: any;
  forwordmaildata: any;
  selectedSelected: any;
  selectedticketid: any;
  friends: boolean;
  friend: any;
  userImage: any;
  forwardimages: boolean = false;
  filedetails: any;
  blkid: any;
  esnid: any;
  showesign: boolean;
  docname1: any;
  docname2: any;
  docname3: any;
  docname4: any;
  docname5: any;
  docname6: any;
  docname7: any;
  docname8: any;
  docname9: any;
  docname10: any;
  CurrentFiles: any;
  selectedattachments: any = [];
  filename: any;
  filenameone: any;
  filenametwo: any;
  filenamethree: any;
  filenamefour: any;
  folderdisaply: boolean;
  subfolderdisaply: boolean;
  allfoldersdisplay: boolean;
  subfolderdetails: any;
  currentfoldername: any;
  FileFoldID: any;
  allSubFolders: any;
  SubFoldersList: any;
  foldernamelist: any;
  allFolders: any;
  fileslist: any;
  allFoldersList: any;
  sharedfolderdisaply: boolean;
  morefiles: boolean;
  fid: any;
  allFolders1: any;
  showforward: boolean = false;
  dat: any;
  color: boolean;
  progress: boolean;
  checkname: any;
  checkitem: any;
  id: any;
  fromid: any;
  Ismailid: any;
  fnameeintrnl: any;
  filenamintrnl: any;
  filenam: any;
  fnamee: any;
  IPAddress: any;
  dnsIP: any;
  downpath: any;
  notify: string;
  GroupList: any;
  selgp: any;
  guidd: any;
  grpusers: any = [];
  frnlistgp: any = [];
  notenterprise: boolean = false;
  frnlist: any = [];
  showtwobut: boolean;
  select: boolean = true;
  public dismissalert = false;
  selsel: any = [];
  showccusers: boolean = false;
  showccmail: boolean;
  selectedFriend1: any = [];
  emailq: any;
  selselcc: any = [];
  showccusers1: boolean;
  innbox: any;
  currentPage: number = 1;
  itemsPerPage: number = 50;
  totalItems: number = 50;
  InboxList: any;
  reademaildata: any;
  sentList: any;
  archiveList:any;
  foundIndex = -1;
  constructor(private router: ActivatedRoute, private userservice: UserDetailsService, private moveService: MovesService,
    private route: Router, private httpService: HttpClient, private modalService: NgbModal, private domSanitizer: DomSanitizer,
    private spinner: NgxSpinnerService, private datepipe: DatePipe, private http: HttpClient,
    private mailservice: MailService, private auditortransactionservice: AuditorTransactionService,
    private groupservice: GroupService, private location: Location,private cdr: ChangeDetectorRef,private encryptionService : EncryptionService) {
    this.router.params.subscribe(params => {
      if (params["id"]) {
        this.checkname = params["id"];
        this.checkitem = this.checkname.split('-');
        this.innbox = this.checkitem[0];
        this.mailid = this.checkitem[1];
      }
    });

    if (localStorage.getItem("IsLoggedIn") == "true") {
      this.isLoggedIn = true;
      this.uid = localStorage.getItem("userId");
      this.roleid = localStorage.getItem("role");
      this.userName = localStorage.getItem("UserName");
      this.pid = localStorage.getItem("parentid");
      this.usermail = localStorage.getItem("MailId");
      this.gridvalue = localStorage.getItem("gridvalue");
      this.GUID = localStorage.getItem("GUID");
      this.fromid = localStorage.getItem("FromUser");
      if (this.roleid != "1") {
        this.select = false;
        this.notenterprise = true;
      }
    }
    this.folderdisaply = true;
  }

  ngOnInit(): void {
    this.GetMailDetais();
    this.GetFriendDetails();
    this.GetFriendDetailsonee();
    this.UserGroup();

    this.cdr.detectChanges();
    if (this.innbox == "inbox") {
      this.GetInboxList();
    }
    else if (this.innbox == "sent") {
      this.GetSentMailList();
    }

  }

  GetInboxList() {

    var info = {
      UID: this.uid
    }
    this.mailservice.getInboxlist(info).subscribe((respose: any) => {
      this.InboxList = respose;
      this.totalItems = this.InboxList.length;


      for (let i = 0; i < this.InboxList.length; i++) {
        const item = this.InboxList[i];

        if (item.mailId === parseInt(this.mailid)) {
          this.foundIndex = i;
          break;
        }
      }

      if (this.foundIndex !== -1) {
        this.currentPage = this.foundIndex + 1;
      } else {
        console.log('Mail ID not found in InboxList');
      }


     // console.log(respose);
      this.spinner.hide();
    });
  }
  GetSentMailList() {
    var info = {
      UID: this.uid
    }
    this.mailservice.getsentlist(info).subscribe((respose: any) => {
      this.sentList = respose;
      this.totalItems = this.sentList.length;


      for (let i = 0; i < this.sentList.length; i++) {
        const item = this.sentList[i];

        if (item.mailId === parseInt(this.mailid)) {
          this.foundIndex = i;
          break;
        }
      }

      if (this.foundIndex !== -1) {
        this.currentPage = this.foundIndex + 1;
      } else {
        console.log('Mail ID not found in InboxList');
      }



    });
  }

  GetarchiveMailList() {
    var info ={
      UID  :this.uid
    }
    this.mailservice.getarchivelist(info).subscribe((respose: any) => {
      this.archiveList = respose;
     
      for (let i = 0; i < this.archiveList.length; i++) {
        const item = this.archiveList[i];

        if (item.mailId === parseInt(this.mailid)) {
          this.foundIndex = i;
          break;
        }
      }

      if (this.foundIndex !== -1) {
        this.currentPage = this.foundIndex + 1;
      } else {
        console.log('Mail ID not found in InboxList');
      }


    });
  }

  ReadMessagePrevious() {

    if (this.innbox == "inbox") {


      this.foundIndex--; // Decrement index for previous message

      if (this.foundIndex >= 0 && this.foundIndex < this.InboxList.length) {
        const selectedItem = this.InboxList[this.foundIndex];
        console.log('Item at index:', selectedItem);


        this.currentPage = this.foundIndex + 1;


        this.reademaildata = 'inbox-' + selectedItem.mailId;

        this.route.navigate(['readmsg', this.reademaildata]);


        var info = {
          UID: selectedItem.mailId,
          FromId: this.uid,
          Checkname: this.checkitem[0],
          Id: this.id,
          ToUser: this.fromid
        }
        this.mailservice.getmaildetails(info).subscribe((respose: any) => {
          this.maildata = respose;
          this.Transid = this.maildata[0].trnsid;
          this.mailsubject = this.maildata[0].subject;
          if (this.maildata[0].images != null) {
            this.forwardimages = true;
            this.message = this.maildata[0].message;
            this.userImage = this.maildata[0].images;
          }
          if (this.maildata[0].subject == "ESignature Required") {
            this.showesign = true;
          }
          console.log(respose)
          this.date = this.maildata.date;
          this.memlist = this.maildata.memlist;


          for (let index = 0; index < this.memlist.length; index++) {
            if (index == 0) {
              this.tomem = this.memlist[index].useName;
            }
            else {
              this.tomem = this.tomem + " , " + this.memlist[index].useName;
            }
          }
          if (respose.documents != null) {
            this.Doc = respose.documents;
            console.log(this.Doc);
            this.docments = true;
          }
          console.log(respose);

        });


      } else {
        alert('No mail Available');
      }
    }
    else if (this.innbox == "sent") {
      this.foundIndex--; // Decrement index for previous message

      if (this.foundIndex >= 0 && this.foundIndex < this.sentList.length) {
        const selectedItem = this.sentList[this.foundIndex];
        console.log('Item at index:', selectedItem);


        this.currentPage = this.foundIndex + 1;


        this.reademaildata = 'sent-' + selectedItem.mailId;

        this.route.navigate(['readmsg', this.reademaildata]);

        var info = {
          UID: selectedItem.mailId,
          FromId: this.uid,
          Checkname: this.checkitem[0],
          Id: this.id,
          ToUser: this.fromid
        }
        this.mailservice.getmaildetails(info).subscribe((respose: any) => {
          this.maildata = respose;
          this.Transid = this.maildata[0].trnsid;
          this.mailsubject = this.maildata[0].subject;
          if (this.maildata[0].images != null) {
            this.forwardimages = true;
            this.message = this.maildata[0].message;
            this.userImage = this.maildata[0].images;
          }
          if (this.maildata[0].subject == "ESignature Required") {
            this.showesign = true;
          }
          console.log(respose)
          this.date = this.maildata.date;
          this.memlist = this.maildata.memlist;


          for (let index = 0; index < this.memlist.length; index++) {
            if (index == 0) {
              this.tomem = this.memlist[index].useName;
            }
            else {
              this.tomem = this.tomem + " , " + this.memlist[index].useName;
            }
          }
          if (respose.documents != null) {
            this.Doc = respose.documents;
            console.log(this.Doc);
            this.docments = true;
          }
          console.log(respose);

        });





      } else {
        alert('No mail Available');
      }
    }
 
  }
  ReadMessagePreviousNext() {

    if (this.innbox == "inbox") {

      this.foundIndex == this.foundIndex++;
      if (this.foundIndex >= 1 && this.foundIndex < this.InboxList.length) {
        const itemAtIndex = this.InboxList[this.foundIndex];
        console.log('Item at index:', itemAtIndex);
        this.currentPage = this.foundIndex + 1

        const selectedItem = itemAtIndex;

        this.reademaildata = 'inbox-' + selectedItem.mailId;

        this.route.navigate(['readmsg/' + this.reademaildata]);

        var info = {
          UID: selectedItem.mailId,
          FromId: this.uid,
          Checkname: this.checkitem[0],
          Id: this.id,
          ToUser: this.fromid
        }
        this.mailservice.getmaildetails(info).subscribe((respose: any) => {
          this.maildata = respose;
          this.Transid = this.maildata[0].trnsid;
          this.mailsubject = this.maildata[0].subject;
          if (this.maildata[0].images != null) {
            this.forwardimages = true;
            this.message = this.maildata[0].message;
            this.userImage = this.maildata[0].images;
          }
          if (this.maildata[0].subject == "ESignature Required") {
            this.showesign = true;
          }
          console.log(respose)
          this.date = this.maildata.date;
          this.memlist = this.maildata.memlist;


          for (let index = 0; index < this.memlist.length; index++) {
            if (index == 0) {
              this.tomem = this.memlist[index].useName;
            }
            else {
              this.tomem = this.tomem + " , " + this.memlist[index].useName;
            }
          }
          if (respose.documents != null) {
            this.Doc = respose.documents;
            console.log(this.Doc);
            this.docments = true;
          }
          console.log(respose);

        });

      } else {
        alert('No mail Available');
      }

    }
    else if (this.innbox == "sent") {
      this.foundIndex == this.foundIndex++;
      if (this.foundIndex >= 1 && this.foundIndex < this.sentList.length) {
        const itemAtIndex = this.sentList[this.foundIndex];
        console.log('Item at index:', itemAtIndex);
        this.currentPage = this.foundIndex + 1

        const selectedItem = itemAtIndex;

        this.reademaildata = 'sent-' + selectedItem.mailId;

        this.route.navigate(['readmsg/' + this.reademaildata]);

        var info = {
          UID: selectedItem.mailId,
          FromId: this.uid,
          Checkname: this.checkitem[0],
          Id: this.id,
          ToUser: this.fromid
        }
        this.mailservice.getmaildetails(info).subscribe((respose: any) => {
          this.maildata = respose;
          this.Transid = this.maildata[0].trnsid;
          this.mailsubject = this.maildata[0].subject;
          if (this.maildata[0].images != null) {
            this.forwardimages = true;
            this.message = this.maildata[0].message;
            this.userImage = this.maildata[0].images;
          }
          if (this.maildata[0].subject == "ESignature Required") {
            this.showesign = true;
          }
          console.log(respose)
          this.date = this.maildata.date;
          this.memlist = this.maildata.memlist;


          for (let index = 0; index < this.memlist.length; index++) {
            if (index == 0) {
              this.tomem = this.memlist[index].useName;
            }
            else {
              this.tomem = this.tomem + " , " + this.memlist[index].useName;
            }
          }
          if (respose.documents != null) {
            this.Doc = respose.documents;
            console.log(this.Doc);
            this.docments = true;
          }
          console.log(respose);

        });

      }
      else {
        alert('No mail Available');
      }
    }

   

  }
  UserGroup() {
    this.spinner.show();
    this.groupservice.getgroup(this.GUID).subscribe((Data: any) => {


      const decryptedData = this.encryptionService.decryptData(Data);
      this.GroupList = decryptedData;
      //this.GroupList = Data;
      this.spinner.hide();
    });
  }

  Selectindividualmail(rowEmail: any): void {
    // Ensure the arrays are initialized only once
    if (!this.selectedFriend) {
      this.selectedFriend = [];
    }
    if (!this.email) {
      this.email = [];
    }
  
    // Get the email from the row
    this.individualmail = rowEmail.email;
  
    // Check if the email exists and is valid
    if (this.individualmail && this.individualmail.includes('@')) {
      // Add the email to the selected arrays
      this.email.push(this.individualmail);
      this.selectedFriend.push(this.individualmail);
  
      // Remove duplicates
      this.selectedFriend = Array.from(new Set(this.selectedFriend));
      this.email = Array.from(new Set(this.email));
    }
  }
  Selectallmail() {
    this.selectedFriend = [];
    this.email = [];
    this.frnlist = this.frndslist;
    for (var i = 0; i < this.frnlist.length; i++) {
      if (this.frnlist[i].email != undefined && this.frnlist[i].email != null) {
        this.email.push(this.frnlist[i].email)
        this.selectedFriend.push(this.frnlist[i].email)
        //for removing duplicates from array list
        this.selectedFriend = Array.from(new Set(this.selectedFriend));
        this.email = Array.from(new Set(this.email));
      }
      else if (this.frnlist[i].includes('@')) {
        this.email.push(this.frnlist[i])
        this.selectedFriend.push(this.frnlist[i])
        //for removing duplicates from array list
        this.selectedFriend = Array.from(new Set(this.selectedFriend));
        this.email = Array.from(new Set(this.email));
      }
    }
  }

  Select() {
    this.showtwobut = true;
    this.select = false;
  }

  selectgrpname(selectgrpname) {
    // this.selectedFriend = [];
    // this.email = [];
    this.selgp = selectgrpname.id;
    this.groupservice.getgrpusers(this.selgp).subscribe((data: any) => {
      this.grpusers = data;
      this.frnlistgp = this.frndslist;
      for (var i = 0; i < this.frnlistgp.length; i++) {
        for (var j = 0; j < this.grpusers.length; j++) {
          if (this.frnlistgp[i].uid == this.grpusers[j].userId) {
            this.email.push(this.frnlistgp[i].email)
            this.selectedFriend.push(this.frnlistgp[i].email)
            //to remove duplicates from array
            this.email = Array.from(new Set(this.email));
            this.selectedFriend = Array.from(new Set(this.selectedFriend));
          }

        }
      }
      console.log(this.grpusers);
      this.spinner.hide()
    })

  }
  inbox() {
    this.route.navigate(['inboxmails']);
  }
  composemail() {
    this.route.navigate(['composemsg']);
  }
  deleteitems() {
    this.route.navigate(['del']);
  }
  sentitems() {
    this.route.navigate(['sentmails']);
  }
  archiveitems() {
    this.route.navigate(['archive']);
  }

  //...... Addnewmembers(data,newmember) {
  //   this.forwordmaildata = data;
  //   this.modalService.open(newmember).result.then((result) => {
  //     this.closeResult = `Closed with: ${result}`;

  //   }, (reason) => {
  //     this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  //   });
  //...... }

  Addnewmembers(data) {
    this.showforward = true;
    this.forwordmaildata = data;
    this.replayenable = false;
  }
  goBack() {
    


    if (this.innbox == "inbox") {
      this.route.navigate(['/inboxmails']);
    }
    else if (this.innbox == "sent") {
      this.route.navigate(['/sentmails']);
    }






  }
  GetMailDetais() {
    this.spinner.show();
    var info = {
      UID: this.mailid,
      FromId: this.uid,
      Checkname: this.checkitem[0],
      Id: this.id,
      ToUser: this.fromid
    }
    this.mailservice.getmaildetails(info).subscribe((respose: any) => {
      this.maildata = respose;
      this.Transid = this.maildata[0].trnsid;
      this.documentsArray = this.maildata[0].documents; 
      this.mailsubject = this.maildata[0].subject;
      if (this.maildata[0].images != null) {
        this.forwardimages = true;
        this.message = this.maildata[0].message;
        this.userImage = this.maildata[0].images;
      }
      if (this.maildata[0].subject == "ESignature Required") {
        this.showesign = true;
      }
      console.log(respose)
      this.date = this.maildata.date;
      this.memlist = this.maildata.memlist;


      for (let index = 0; index < this.memlist.length; index++) {
        if (index == 0) {
          this.tomem = this.memlist[index].useName;
        }
        else {
          this.tomem = this.tomem + " , " + this.memlist[index].useName;
        }
      }
      if (respose.documents != null) {
        this.Doc = respose.documents;
        console.log(this.Doc);
        this.docments = true;
      }
      console.log(respose);
      this.spinner.hide();
    });
  }


  ViewESignFile(data) {
    this.filedetails = data;
    this.blkid = this.filedetails.documents[0].documentType;
    this.esnid = this.filedetails.documents[0].doclink;
    localStorage.setItem("PdfblockidRecipients", this.blkid);
    localStorage.setItem("esignblockid", this.esnid);
    this.route.navigate(['/viewer']);
  }

  GetFriendDetailsonee() {
    this.userservice.friendlist(this.GUID).subscribe((respose: any) => {


      const decryptedData = this.encryptionService.decryptData(respose);
      this.Friends = decryptedData;

      //this.Friends = respose;
      this.frndslistone = this.Friends.friendsList;
      // console.log(this.frndslist);
      var idd = this.accid;

      for (let i = 0; i < this.frndslist.length; i++) {
        if (idd != undefined) {
          if (idd.includes('@')) {
            if (this.frndslist[i]['email'] == this.accid) {
              for (i = 0; i < 100; i++) {
                window.clearInterval(i);
              }
              alert(this.accid + " has accepted your Friend request");
              this.accid = "";
            }
          }
        }
        if (this.frndslist[i]['friendId'] == this.uid) {
          this.frndslist[i]['friendId'] = this.frndslist[i]['userId']
        }
      }
      // console.log(this.frndslist);

    });
  }
  GetFriendDetails() {
    this.userservice.GetFriendRequests(this.GUID).subscribe((respose: any) => {

      const decryptedData = this.encryptionService.decryptData(respose);
      this.Friends = decryptedData;
      this.frndslist = this.Friends;
     // console.log(this.frndslist);
      // console.log(this.frndslist);
      var idd = this.accid;
      for (let i = 0; i < this.frndslist.length; i++) {
        if (idd != undefined) {
          if (idd.includes('@')) {
            if (this.frndslist[i]['email'] == this.accid) {
              for (i = 0; i < 100; i++) {
                window.clearInterval(i);
              }
              alert(this.accid + " has accepted your Friend request");
              this.accid = "";
            }
          }
        }
        if (this.frndslist[i]['friendId'] == this.uid) {
          this.frndslist[i]['friendId'] = this.frndslist[i]['userId']
        }
      }
    });
  }
  onItemRemoved(value: any) {
    //this.selecteduserlist.pop(value.display);
    for (var i = 0; i < this.frndslist.length; i++) {
      //......    if (this.frndslist[i].Email == value.display) {
      if (this.frndslist[i].email == value.display && value.email != undefined) {
        var index = this.selectedFriend.indexOf(value);
        this.selectedFriend.splice(index, 1);
      }
      else if (value == this.frndslist[i].email) {
        var index = this.selectedFriend.indexOf(value);
        this.selectedFriend.splice(index, 1);
      }
    }
    this.errorMessages = "";
  }

  onItemRemoved1(value: any) {
    //this.selecteduserlist.pop(value.display);
    for (var i = 0; i < this.frndslist.length; i++) {
      //......    if (this.frndslist[i].Email == value.display) {
      if (this.frndslist[i].email1 == value.display && value.email1 != undefined) {
        var index = this.selectedFriend1.indexOf(value);
        this.selectedFriend1.splice(index, 1);
      }
      else if (value == this.frndslist[i].email1) {
        var index = this.selectedFriend1.indexOf(value);
        this.selectedFriend1.splice(index, 1);
      }
    }
    this.errorMessages = "";
  }
  onItemAdded1(value: any) {
    if (value.email1 == undefined) {
      this.emailq = value.value;
    }
    else {
      this.emailq = value.email1;
    }
    this.serchfind = this.regexp.test(this.emailq);
    if (!this.serchfind) {
      this.errorMessages = "Please Enter valid Email";
    }
    if (value.uid != null) {
      this.selectedFriend1.push(value);
    }
    else {
      alert("Oops!! This person not in your Friends list.Let us add him/her Click Friend Request.");
      this.friends = true;
      this.friend = this.emailq;
      this.selectedFriend = "";
      // this.savefrienddetails();
    }
    // else {
    //   this.selectedFriend.push(value.email);
    // }
  }

  // onItemAdded(value: any) {
  //   if (value.Email == undefined) {
  //     this.email = value.value;
  //   }
  //   else {
  //     this.email = value.Email;
  //   }
  //   this.serchfind = this.regexp.test(this.email);
  //   if (!this.serchfind) {
  //     this.errorMessages = "Please Enter valid Email";
  //   }
  //   if (value.uid != null) {
  //     this.selectedFriend.push(value);
  //   }
  //   else {
  //     this.selectedFriend.push(value.value);
  //   }
  // }
  onItemAdded(value: any) {
    if (value.email == undefined) {
      this.email = value.value;
    }
    else {
      this.email = value.email;
    }
    this.serchfind = this.regexp.test(this.email);
    if (!this.serchfind) {
      this.errorMessages = "Please Enter valid Email";
    }
    if (value.uid != null) {
      this.selectedFriend.push(value);
    }
    else {
      alert("Oops!! This person not in your Friends list.Let us add him/her Click Friend Request.");
      this.friends = true;
      this.friend = this.email;
      this.selectedFriend = "";
      // this.savefrienddetails();
    }
    // else {
    //   this.selectedFriend.push(value.email);
    // }
  }
  // attachmentmail(){
  //   var det = {
  //     TicketId: this.selectedticketid,
  //     FromId: this.uid,
  //     MultiToUser: this.selectedFriend,
  //     Blocdrivedoc:this.selectedSelected,
  //     FromuserGUID: this.GUID,
  //     MailId: this.forwordmaildata.mailId,
  //     TransId:this.forwordmaildata.trnsid,
  //     Message:this.message
  //     //Externalmail:null,
  //   }
  //   //this.frmDataa.append("Drive", this.selectedSelected[i]);
  //   this.frmDataa.append("MessageData", JSON.stringify(det));
  //   this.frmDataa.append("fileUpload", "SelectedFiles");

  //   this.spinner.show();
  //   this.httpService.post("https://blocdrive.com/blocdriveapi" + '/api/UploadFile/forwardmailattachment/', this.frmDataa).subscribe(
  //     data => {
  //       this.spinner.hide();
  //       this.selectedFriend= this.selectedSelected;
  //      // alert("Mail Sent Successfully"); 
  //       this.mailsubject = "";
  //       this.message = "";
  //       window.location.reload();
  //       // this.route.navigate(['sentmails']);

  //     },
  //     (err: HttpErrorResponse) => {
  //       console.log(err.message);
  //     });

  // }
  savefrienddetails() {
    this.spinner.show();
    const inputRequest: Friend = {
      Id: 0,
      FriendAccount: this.friend,
      UserId: this.uid,
      Status: 4,
      IsEnabled: true,
      GUID: this.GUID

    }
    this.userservice.SaveFrienddetails(inputRequest).subscribe((data: any) => {
      if (data == "SameUser") {
        alert("Please enter your Friend mailid!!");

      }
      else if (data == "alreadyfriends") {
        alert("You are already Friends!!");
        this.friends = false;
      }
      else if (data == "NoUserFound") {
        alert("We are sorry " + this.accid + " is not using Blocdrive!!");
        this.friends = false;
      }
      else if (data == "SentRequest") {
        alert("You have already sent or recived request from this user!!");
        this.friends = false;
      }
      else if (data == "1") {
        alert("Friend request sent successfully!!!");
        this.friends = false;
        const audit = {
          TransactionName: "Friend Request",
          TransactionType: "Send",
          TransactionDesc: "Friend Request",

        }
        this.auditortransactionservice.AuditorTransactionDetails(audit);
      }
      else {
        alert("Something went wrong");
        // this.route.navigate(['/allfolders']);
      }
      this.spinner.hide();
      this.accid = "";

    });
  }

  //forward mail with and without attachment
  forword() {
    //this.attachmentmail();
    for (var j = 0; j < this.selectedFriend.length; j++) {

      if (this.selectedFriend[j].email == undefined) {
        for (var m = 0; m < this.frndslist.length; m++) {
          if (this.frndslist[m].email == this.selectedFriend[j]) {
            this.selsel.push(this.frndslist[m])
            break;
          }
        }
      }
      else if (this.selectedFriend[j].email != undefined) {
        for (var n = 0; n < this.frndslist.length; n++) {
          if (this.frndslist[n].email == this.selectedFriend[j].email) {
            this.selsel.push(this.frndslist[n])
            break;
          }
        }
      }
    }
    //for removing duplicates from an array
    this.selsel = Array.from(new Set(this.selsel));
    this.selsel.forEach(item => {
      item.ccopy = "no";
    });
    console.log(this.selsel);
    //......
    for (var j = 0; j < this.selectedFriend1.length; j++) {

      if (this.selectedFriend1[j].email1 == undefined) {
        for (var m = 0; m < this.frndslist.length; m++) {
          if (this.frndslist[m].email1 == this.selectedFriend1[j]) {
            this.selselcc.push(this.frndslist[m]);
            break;
          }
        }
      }
      else if (this.selectedFriend1[j].email1 != undefined) {
        for (var n = 0; n < this.frndslist.length; n++) {
          if (this.frndslist[n].email1 == this.selectedFriend1[j].email1) {
            this.selselcc.push(this.frndslist[n])
            break;
          }
        }
      }
    }
    //for removing duplicates from an array
    this.selselcc = Array.from(new Set(this.selselcc));
    this.selselcc.forEach(item => {
      item.ccopy = "yes";
    });

    //to combine 2 array lists
    this.selsel.push.apply(this.selsel, this.selselcc);
    console.log(this.selsel);

    if (this.frmDataa == null) {
      this.frmDataa = new FormData();
    }
    var det = {
      TicketId: this.selectedticketid,
      FromId: this.uid,
      //......    MultiToUser: this.selectedFriend,
      MultiToUser: this.selsel,
      Blocdrivedoc: this.selectedSelected,
      blocdriveattachments: this.selectedattachments,
      FromuserGUID: this.GUID,
      MailId: this.forwordmaildata.mailId,
      TransId: this.forwordmaildata.trnsid,
      Message: this.message,
      Id: this.forwordmaildata.id,
      Subject: this.forwordmaildata.subject

    }
    if (this.SelectedFiles != 0) {
      this.frmDataa.append("MessageData", JSON.stringify(det));
      this.frmDataa.append("fileUpload", "SelectedFiles");
    }
    else {
      this.frmDataa.append("MessageData", JSON.stringify(det));
    }

    this.spinner.show();
    // this.httpService.post("http://localhost:8540" + '/api/UploadFile/forwardmailattachment/', this.frmDataa).subscribe(
    //   data => {
    //     this.spinner.hide();
    //    // this.selectedFriend= this.selectedSelected;
    //     this.mailsubject = "";
    //     this.message = "";

    //     if(data == "0"){
    //       (err: HttpErrorResponse) => {
    //         console.log(err.message);
    //       };
    //       window.location.reload();  
    //     }   

    // var aa={
    //   MultiToUser: this.selectedFriend,
    //   MailId: this.forwordmaildata.mailId,
    //   TransId:this.forwordmaildata.trnsid,
    //   Id:this.forwordmaildata.id,
    //   FromId:this.uid
    // };
    this.httpService.post("https://blocdrive.com/blocdriveapi" + '/api/UploadFile/PostAddNewMembers', this.frmDataa).subscribe((respose: any) => {
      if (respose == "Succ") {
        this.GetMailDetais();
        //......    alert("Mail forwarded successfully!!!");
        this.spinner.hide();
        //......     window.location.reload();
        const audit = {
          TransactionName: "Mail",
          TransactionType: "Forward",
          TransactionDesc: "Forwarded Mail",
        }
        this.auditortransactionservice.AuditorTransactionDetailsFileMail(audit);
        // Delay for 3 seconds and then hide the notification and reload the page
        this.notify = "Mail sent successfully!!!";
        this.dismissalert = true;
        setTimeout(() => {
          this.dismissalert = false;
          this.mailsubject = "";
          this.message = "";
          this.route.navigate(['inboxmails']);
        }, 2000);
        //
      }
      else {
        alert("Something went worng");
      }

    });
    // })
  }

  // forword(){
  //   this.attachmentmail();
  //   var aa={
  //     MultiToUser: this.selectedFriend,
  //     MailId: this.forwordmaildata.mailId,
  //     TransId:this.forwordmaildata.trnsid,
  //     Id:this.forwordmaildata.id
  //   };
  //   this.userservice.postaddnewebers(aa).subscribe((respose: any) => {
  //    if(respose =="Succ"){
  //     alert("Mail Forwarded Successfully");
  //     const audit = {
  //       TransactionName:"Mail",
  //       TransactionType:"Forward",
  //       TransactionDesc:"Forwarded Mail",

  //       }
  //       this.auditortransactionservice.AuditorTransactionDetailsFileMail(audit);
  //    }
  //    else{
  //     alert("Something went worng");
  //    }

  //   });
  // }
  replay(mail, aa) {
    // var qq = document.getElementById(mail.id);
    // qq.setAttribute(visibility,"visible");
    this.replayenable = true;
    this.showforward = false;
    this.replytouser = "";
    this.Ismailid = mail.isMailId

    if (aa == 'single') {
      this.replytouser = mail.fromName;
      this.selectedFriend.push(mail.fromId);
    }
    else if (aa == 'all') {
      //......      this.replytouser = mail.fromName;
      this.replytouser = "";
      this.selectedFriend.push(mail.fromId);
      for (let index = 0; index < mail.memlist.length; index++) {
        if (mail.memlist[index].uid != this.uid) {
          if (this.replytouser == "") {
            this.replytouser = mail.memlist[index].useName;
          }
          else {
            this.replytouser = this.replytouser + " , " + mail.memlist[index].useName;
          }

          this.selectedFriend.push(mail.memlist[index].uid);
        }
      }
    }

    window.scrollTo(0, document.body.scrollHeight);


  }

  // onChangeDocumnets(event: any) {
  //   this.frmDataa = new FormData();
  //   let files = [].slice.call(event.target.files);
  //   for (var i = 0; i < files.length; i++) {
  //     this.SelectedFiles.push(files[i]);
  //     this.frmDataa.append("Docs", files[i]);
  //   }
  //   this.docname1 = this.SelectedFiles[0].name ;
  //   this.docname2 = this.SelectedFiles[1].name ;
  //   this.docname3 = this.SelectedFiles[2].name ;
  //   this.docname4 = this.SelectedFiles[3].name ;
  //   this.docname5 = this.SelectedFiles[4].name ;
  //   this.docname6 = this.SelectedFiles[5].name ;
  //   this.docname7 = this.SelectedFiles[6].name ;
  //   this.docname8 = this.SelectedFiles[7].name ;
  //   this.docname9 = this.SelectedFiles[8].name ;
  //   this.docname10 = this.SelectedFiles[9].name ;

  // }
  onChangeDocumnets(event: any) {
    this.frmDataa = new FormData();
    let files = [].slice.call(event.target.files);
    for (var i = 0; i < files.length; i++) {
      this.SelectedFiles.push(files[i]);
      this.frmDataa.append("Docs", files[i]);
      // this.color = true;
    }
    var usr = {
      UserId: this.uid
    }
    this.frmDataa.append("UserIDD", JSON.stringify(usr));
    // if(files.length == 1){
    this.progress = true;
    this.httpService.post("https://blocdrive.com/blocdriveapi" + '/api/UploadFile/PostSavesinglefile/', this.frmDataa).subscribe((data: any) => {
      this.progress = false;
      this.dat = data;
      this.color = true;
      // this.delfile = true;
      this.spinner.hide()
    })

    // }
  }
  RemoveFile(data) {
    var det = {
      FileName: data.name,
      UserId: this.uid,
    }

    this.httpService.post("https://blocdrive.com/blocdriveapi" + '/api/UploadFile/PostRemoveFile/', det).subscribe((data: any) => {
      this.fnamee = data;
      this.filenam = this.fnamee.documentName;
      for (let ri = 0; ri < this.SelectedFiles.length; ri++) {
        if (this.filenam == this.SelectedFiles[ri].name) {
          const index1 = this.SelectedFiles.indexOf(this.SelectedFiles[ri], 0);
          this.SelectedFiles.splice(index1, 1);
        }
      }

    })

  }

  RemoveInternalFile(data) {
    var det = {
      FileName: data.documentName,
      UserId: this.uid,
    }

    this.httpService.post("https://blocdrive.com/blocdriveapi" + '/api/UploadFile/PostRemoveInternalFile/', det).subscribe((data: any) => {
      this.fnameeintrnl = data;
      this.filenamintrnl = this.fnameeintrnl.documentName;
      for (let ri = 0; ri < this.selectedattachments.length; ri++) {
        if (this.filenamintrnl == this.selectedattachments[ri].documentName) {
          const index1 = this.selectedattachments.indexOf(this.selectedattachments[ri], 0);
          this.selectedattachments.splice(index1, 1);
        }
      }

    })

  }

  ccmail() {
    this.showccmail = true;
  }

  sendmail() {
    for (var k = 0; k < this.selectedFriend1.length; k++) {
      if (this.selectedFriend1[k].uid != undefined && this.selectedFriend1[k].uid != null) {
        this.selectedFriend1[k].uid = this.selectedFriend1[k].uid + "," + "yes";
        this.selectedFriend.push(this.selectedFriend1[k].uid);
        this.selectedFriend1[k].uid = [];
      }
    }

    if (this.frmDataa == null) {
      this.frmDataa = new FormData();
    }
    var det = {
      TicketId: null,
      FromId: this.uid,
      ReplyMultiToUser: this.selectedFriend,
      Subject: this.mailsubject,
      Message: this.message,
      FromuserGUID: this.GUID,
      MailId: this.mailid,
      TransId: this.Transid,
      IsMailIdReply: this.Ismailid

    }
    this.frmDataa.append("MessageData", JSON.stringify(det));
    this.spinner.show();
    this.httpService.post("https://blocdrive.com/blocdriveapi" + '/api/UploadFile/PostSaveReplayMail/', this.frmDataa).subscribe(
      data => {
        this.spinner.hide();
        //......   alert("Mail sent successfully!!!");
        const audit = {
          TransactionName: "Mail",
          TransactionType: "Send",
          TransactionDesc: "Reply Mail",

        }
        this.auditortransactionservice.AuditorTransactionDetailsFileMail(audit);
        this.notify = "Mail sent successfully!!!";
        this.dismissalert = true;

        // Delay for 3 seconds and then hide the notification and reload the page
        setTimeout(() => {
          this.dismissalert = false;
          this.mailsubject = "";
          this.message = "";
          this.route.navigate(['inboxmails']);

        }, 2000);
        //
      },
      (err: HttpErrorResponse) => {
        console.log(err.message);
      });
  }
  ViewImageDoc(aa, content) {
    if (aa.pdfFile != null) {
      // this.ViewDoc(aa)
    }
    else {
      this.imgdisplay = aa;
      this.modalService.open(content).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        this.m = this.msg;
      });
    }
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  ViewDocument(aa, pdftesting) {
    if (aa.pdfFile != null) {
      // this.ViewDoc(aa)
    }
    else {

      this.zzz = aa;
      this.current_url = this.domSanitizer.bypassSecurityTrustResourceUrl(this.zzz.file);
      console.log(this.pdfUrl);
      this.pdfUrl = this.zzz.doclink;
      // this.doc="http://docs.google.com/gview?"+this.zzz.file+"&embedded=true";

      this.modalService.open(pdftesting).result.then((result) => {

        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        this.m = this.msg;
        console.log(this.m)

      });
    }
  }
  ViewVideo(cc, videocontent) {
    this.videoplay = cc.doclink
    this.videodate = this.date;
    // this.folderviewservice.viewDocument(cc).subscribe((data: any) => {
    //   this.pdfUrl = data;
    this.modalService.open(videocontent).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m = this.msg;
    });
    //});
  }

  Viewaudio(cc, videocontent) {
    this.audioplay = cc.doclink
    this.audiodate = this.date;
    // this.folderviewservice.viewDocument(cc).subscribe((data: any) => {
    //   this.pdfUrl = data;
    this.modalService.open(videocontent).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m = this.msg;
    });
    //});
  }
  MailFriend() {
    this.route.navigate(['/friendrequest']).then(() => {
      window.location.reload();
    })
  }





  // haspit section
  onClick(data) {
    this.CurrentFiles = data;

    if (data != null) {
      if (this.selectedattachments.length == 0) {
        // document.getElementById(data.Id.toString()).style.backgroundColor = "#4f44c5";
        // document.getElementById(data.Id.toString()).style.color = "white";
        this.selectedattachments.push(data);
      }
      else {
        const index = this.selectedattachments.indexOf(data, 0);
        if (index > -1) {
          this.selectedattachments.splice(index, 1);
          // document.getElementById(data.Id.toString()).style.backgroundColor = "";
          // document.getElementById(data.Id.toString()).style.color = "";
        }
        else {
          this.selectedattachments.push(data);
          // document.getElementById(data.Id.toString()).style.backgroundColor = "#4f44c5";
          // document.getElementById(data.Id.toString()).style.color = "white";
        }
      }
      //this.SelectedFiles.push(this.selectedattachments)
    }

  }
  // onClick(data) {
  //   this.CurrentFiles = data;
  //   if (data != null) {
  //     for (var i = 0; i < this.selectedSelected.length; i++) {
  //       if (this.selectedSelected[i].Id == data.Id) {
  //         var index = this.selected.indexOf(data);
  //         this.selectedSelected.splice(index, 1);
  //         this.remove = true;
  //       }
  //     }
  //     this.selectedSelected.push(data);
  //   }

  // }
  uploadmypersonalfiles() {
    this.frmDataa = new FormData();
    var filedata = {
      InternalBdrive: this.selectedattachments
    }
    var usr = {
      UserId: this.uid,
    }
    this.frmDataa.append("UserIDD", JSON.stringify(usr));
    this.frmDataa.append("Attach", JSON.stringify(filedata));

    this.httpService.post("https://blocdrive.com/blocdriveapi" + '/api/UploadFile/PostSavesinglefileInternal/', this.frmDataa).subscribe((data: any) => {
    })


    this.filename = this.selectedattachments[0].documentName;
    this.filenameone = this.selectedattachments[1].documentName;
    this.filenametwo = this.selectedattachments[2].documentName;
    this.filenamethree = this.selectedattachments[3].documentName;
    this.filenamefour = this.selectedattachments[4].documentName;

    // this.isvisible = true;
    // this.frmDataa = new FormData();
    // for (var i = 0; i < this.selectedSelected.length; i++) {
    //   // this.frmData = new FormData();
    //   this.AttachmnetsList.push(this.selectedSelected[i].documentName);
    //   this.frmDataa.append("Drive", this.selectedSelected[i]);
    //   this.blocdriveFiles = this.selectedSelected[i].documentName;

    // }
  }

  GetSubfolderdetails(data) {
    this.folderdisaply = false;
    this.subfolderdisaply = true;
    this.allfoldersdisplay = false;
    this.subfolderdetails = data;
    this.currentfoldername = data.folderName;
    this.FileFoldID = data.folderId;
    this.GetPFolderlist(data.folderId);
    this.spinner.show();
    const dataaa: ShareIdShow = {
      EID: "",
      Fid: data.folderId,
      LoginUID: this.uid
    }
    this.mailservice.getsubfolders(dataaa).subscribe((respose: any) => {
      this.allSubFolders = respose;
      this.spinner.hide();
      for (let i = 0; i < this.allSubFolders.retData.length; i++) {
        if (this.allSubFolders.retData[i].file != null) {
          //......          this.allSubFolders.retData[i].file = this.domSanitizer.bypassSecurityTrustResourceUrl(this.allSubFolders.retData[i].file)
          this.allSubFolders.retData[i].file = this.allSubFolders.retData[i].file;
          if (this.allSubFolders.retData[i].DocumentType == 'image/jpeg' || this.allSubFolders.retData[i].DocumentType == 'image/png'
            || this.allSubFolders.retData[i].DocumentType == 'png' || this.allSubFolders.retData[i].DocumentType == 'jpeg' || this.allSubFolders.retData[i].DocumentType == 'jpg'
            || this.allSubFolders.retData[i].DocumentType == 'PNG') {
            this.SubFoldersList.push(this.allSubFolders.retData[i]);
          }
        }
      }

    });
  }
  GetPFolderlist(id) {
    // this.spinner.show();
    this.mailservice.getpfoldernames(id).subscribe((respose: any) => {
      // this.spinner.hide();
      this.foldernamelist = respose;
      console.log(this.foldernamelist);
    });
  }

  GetMypersonalfolders() {
    this.foldernamelist = null;
    this.currentfoldername = null;
    this.mailservice.getfolders(this.uid).subscribe((respose: any) => {
      this.allFolders = respose;
      this.fileslist = this.allFolders.retData;
      for (var i = 0; i < this.fileslist.length; i++) {
        if (this.fileslist[i].DocumentType == 'image/jpeg' || this.fileslist[i].DocumentType == 'image/png'
          || this.fileslist[i].DocumentType == 'png' || this.fileslist[i].DocumentType == 'jpeg' || this.fileslist[i].DocumentType == 'jpg'
          || this.fileslist[i].DocumentType == 'PNG') {
          var dataa = this.fileslist[i];
          this.allFoldersList.push(dataa);
        }
      }
      this.folderdisaply = true;
      this.subfolderdisaply = false;
      this.spinner.hide();
    });
    this.folderdisaply = true;
    this.sharedfolderdisaply = true;
  }

  closecurrentpopup() {
    var a = document.getElementById("closetwo").click();
    var a = document.getElementById("closeshare").click();
    var a = document.getElementById("closedelete").click();
    this.folderdisaply = true;
    this.subfolderdisaply = false;
    this.sharedfolderdisaply = true;
    this.sharedfolderdisaply = false;
    //this.GetAllSharingFolders();
  }

  selectfile(data) {
    if (data != null) {
      if (this.selectedSelected.length == 0) {
        this.morefiles = false;
        document.getElementById(data.Id.toString()).style.backgroundColor = "#F6F3FD";
        this.selectedSelected.push(data);
      }
      else {
        const index = this.selectedSelected.indexOf(data, 0);
        if (index > -1) {
          this.selectedSelected.splice(index, 1);
          document.getElementById(data.Id.toString()).style.backgroundColor = "";
        }
        else {
          this.selectedSelected.push(data);
          document.getElementById(data.Id.toString()).style.backgroundColor = "#F6F3FD";
        }
      }
      if (this.selectedSelected.length == 0) {
        this.morefiles = false;
      }
    }
    if (this.selectedSelected.length <= 1) {
      this.morefiles = false;
    }
    else if (this.selectedSelected.length >= 2) {
      this.morefiles = true;
    }
  }
  GetAllFolders() {
    //this.spinner.show();
    this.userservice.getfolders(this.GUID).subscribe((respose: any) => {
      this.allFolders = respose;
      // console.log("user", this.allFolders)
    });
  }
  GetAllFolders1() {
    this.userservice.getfolders(this.GUID).subscribe((respose: any) => {
      this.allFolders1 = respose;
      // console.log("user", this.allFolders);
      // this.spinner.hide();
    });
  }

  GetDetails(details) {
    this.folderdisaply = false;
    this.subfolderdisaply = true;
    this.FileFoldID = details.folderID;
    this.currentfoldername = details.folderName;
    // this.spinner.show();
    this.allfoldersdisplay = false;
    this.GetPFolderlist(details.folderID);
    const dataaa: ShareIdShow = {
      EID: "",
      Fid: details.folderID,
      LoginUID: this.uid
    }
    this.mailservice.getsubfolders(dataaa).subscribe((respose: any) => {
      this.allSubFolders = respose;
      this.currentfoldername = this.allSubFolders.FileName;
      this.fid = this.allSubFolders.ParentId;
      // this.spinner.hide();
      for (let i = 0; i < this.allSubFolders.RetData.length; i++) {
        if (this.allSubFolders.RetData[i].file != null) {
          this.allSubFolders.RetData[i].file = this.domSanitizer.bypassSecurityTrustResourceUrl(this.allSubFolders.RetData[i].file);
          if (this.allSubFolders.RetData[i].DocumentType == 'image/jpeg' || this.allSubFolders.RetData[i].DocumentType == 'image/png'
            || this.allSubFolders.RetData[i].DocumentType == 'png' || this.allSubFolders.RetData[i].DocumentType == 'jpeg' || this.allSubFolders.RetData[i].DocumentType == 'jpg'
            || this.allSubFolders.RetData[i].DocumentType == 'PNG') {
            this.SubFoldersList.push(this.allSubFolders.RetData[i]);
          }
        }
      }
    });
  }



  //download file by its original name
  abc(data) {
    const inputRequest = {
      Id: data.Id,
      AUID: this.uid,
      FolderId: data.FolderId,
      FolderName: data.FolderName,
      BlockID: data.BlockId,
      ipaddress: this.IPAddress,
      dnsip: this.dnsIP,
      browser: null,
      transaction: null,
      Doclink: data.doclink,
      DocumentName: data.documentName
    }
    this.userservice.downloadFile(inputRequest).subscribe((data: any) => {
      if (data != null) {
        this.downpath = data;
        window.open(this.downpath);
      }
      else {
        this.notify = "Something went wrong. Try again.!!"
      }
    });
  }

}