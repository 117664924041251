import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  date: Date;
  converttostringdate: string;
  copyrightdate : Date = new Date();
  constructor(private datepipe:DatePipe) {
  var date = new Date
    this.converttostringdate = this.datepipe.transform(date, 'yyyy');

   }

  ngOnInit(): void {
  }

}
