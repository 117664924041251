import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MenuMasterService } from '../Shared/MenuMaster/menu-master.service';
import * as $ from 'jquery';
import { EncryptionService } from '../Shared/encryption/encryption.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  Menus: any;
  userrole: string;
  isLoggedIn: boolean;
  roleid: string;
  mobileview: boolean;
  uid: string;

  constructor(private router:Router,private spinner:NgxSpinnerService,
    private menuservice:MenuMasterService,private encryptionService:EncryptionService) {
    this.userrole = localStorage.getItem("role");
    this.roleid=localStorage.getItem("role");
    this.uid = localStorage.getItem("userId");
    this.isLoggedIn = true;
    this.isMobileMenu();
   }

   isMobileMenu() {
    if ($(window).width() > 991) {
       this.mobileview = false;
    }
    else {
      this.mobileview= true;
    }
  };

  ngOnInit(): void {
    this.GetAllMenus();
  }
  gotousers(){
    this.router.navigate(['/userdetails'])
  }
  gotoaddtask(){
    this.router.navigate(['/addtask'])

  }
  gotoassigntask(){
    this.router.navigate(['/assigntask'])

  }
  gotoproject(){
    this.router.navigate(['/addproject'])

  }
  gotoclients(){
    this.router.navigate(['/client'])

  }
  gotoadmindashboard(){
    this.router.navigate(['/admindashboard'])
  }
  gotoworkflow(){
    this.router.navigate(['/admindashboard'])

  }
  gotorespectivepage(item){
    this.router.navigate([item.menuURL])

  }
  GetAllMenus() {
    this.spinner.show();
    this.menuservice.getadmindashboardmenus(this.userrole + "," + this.uid).subscribe((response: any) => {
      const decryptedData = this.encryptionService.decryptData(response);
      this.Menus = decryptedData;
      //this.Menus = response;
      this.spinner.hide();

    })
  }
  gotoCrmPage(): void {
    const externalUrl = 'https://blocdrive.com/CustomerManager/#/';
    window.open(externalUrl, '_blank'); // Use '_blank' for a new tab or '_self' for the same tab
  }
}
