import { Component, OnInit } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuditorTransactionService } from 'src/app/Shared/auditortransaction/auditor-transaction.service';
import { EncryptionService } from 'src/app/Shared/encryption/encryption.service';
import { GroupService } from 'src/app/Shared/Group/group.service';
import { GroupVM } from 'src/app/ViewModels/group';

@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.css']
})
export class GroupComponent implements OnInit {
  closeResult: any;
  LocationName: any;
  BranchDesc: any;
  branchdesc: any;
  public dismissalert = false;
  notify: string;
  p: number = 1;
  editdesc: any;
  editgroupid: any;
  ubranchname: any;
  ubranchdesc: any;
  createdon: any;
  createdby: any;
  DeleteName: any;
  deleteid: any;
  uid: string;
  branchid: string;
  mobileview: boolean;
  GUID: string;
  groupname: any;
  GroupList: any;
  editgroupname: any;
  constructor(private modalService: NgbModal,private auditortransactionservice:AuditorTransactionService
    , private spinner: NgxSpinnerService,private groupservice: GroupService,private encryptionService : EncryptionService) { 
    if (localStorage.getItem("IsLoggedIn") == "true") {
      this.branchid = localStorage.getItem("branch");
      this.uid = localStorage.getItem("userId");   
     this.GUID = localStorage.getItem("GUID");

    }
    this.isMobileMenu();
  }

  ngOnInit(): void {
    this.UserGroup();
   // this.Branchdata();
    this.groupname = "";
    this.BranchDesc = "";
  }
  isMobileMenu() {
    if ($(window).width() > 991) {
       this.mobileview = false;
    }
    else {
      this.mobileview= true;
    }
  };

  UserGroup() {
    this.spinner.show();
    this.groupservice.getgroup(this.GUID ).subscribe((Data: any) => {
      const decryptedData = this.encryptionService.decryptData(Data);
      this.GroupList = decryptedData;
      //this.GroupList = Data;
      this.spinner.hide();
    });
  }

  addGroup(addbranch) {
    this.groupname = "";

    this.modalService.open(addbranch).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  EditGroup(updatedata, updategroup) {
    this.editgroupname = updatedata.groupName;
    this.editdesc = updatedata.branchDesc;
    this.editgroupid = updatedata.id;
    this.createdon = updatedata.createdOn;
    this.createdby = updatedata.createdBy;
    this.modalService.open(updategroup).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  Deletegroup(deletedata, delgroup) {
    this.DeleteName = deletedata.groupName;
    this.deleteid = deletedata.id;
    this.modalService.open(delgroup).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
  Savegroup() {
    const inputRequest: GroupVM = {
      Id: 0,
      GroupName: this.groupname,
      CreatedBy: "",
      CreatedOn: "",
      UserId: this.uid,
      GUID:this.GUID 
    };
    this.spinner.show();
    this.groupservice.savegroup(inputRequest).subscribe((data: any) => {
      if (data == "success") {
        this.notify = "Group added successfully";
        const audit = {
          TransactionName:"Master",
          TransactionType:"Create",
          TransactionDesc:"Group Master",

          }
          this.auditortransactionservice.AuditorTransactionDetails(audit);
        setTimeout(() => (this.dismissalert = true), 100);
        setTimeout(
          function () {
            this.dismissalert = false;
          }.bind(this),
          3000
        );
        this.spinner.hide();
      this.UserGroup();
      } else {
        this.notify = "Something Went Wrong. Try again.!!";
        setTimeout(() => (this.dismissalert = true), 100);
        setTimeout(
          function () {
            this.dismissalert = false;
          }.bind(this),
          3000
        );
        this.spinner.hide();
     this.UserGroup();
      }
    });
  }

  Updategroup() {
    const inputRequest1: GroupVM = {
      Id: this.editgroupid,
      GroupName: this.editgroupname,
      CreatedBy: this.createdby,
      CreatedOn: this.createdon,
      UserId: this.uid ,
      GUID:this.GUID 


    };
    this.spinner.show();
    this.groupservice.updategroup(inputRequest1).subscribe((data: any) => {
      if (data == "success") {
        this.notify = "Group updated successfully";
        const audit = {
          TransactionName:"Master",
          TransactionType:"Update",
          TransactionDesc:"Group master",

          }
          this.auditortransactionservice.AuditorTransactionDetails(audit);
        setTimeout(() => (this.dismissalert = true), 100);
        setTimeout(
          function () {
            this.dismissalert = false;
          }.bind(this),
          3000
        );
      this.UserGroup();
        this.spinner.hide();
      } else {
        this.notify = "Something Went Wrong. Try again.!!";
        setTimeout(() => (this.dismissalert = true), 100);
        setTimeout(
          function () {
            this.dismissalert = false;
          }.bind(this),
          3000
        );
     this.UserGroup();
        this.spinner.hide();
      }
    });
  }
  Deletegrouprow() {
    this.spinner.show();
    this.groupservice.deletegroup(this.deleteid).subscribe((data: any) => {
      if (data == "success") {
        this.notify = "Group deleted successfully";
        const audit = {
          TransactionName:"Master",
          TransactionType:"Delete",
          TransactionDesc:"Group master",

          }
          this.auditortransactionservice.AuditorTransactionDetails(audit);
        setTimeout(() => (this.dismissalert = true), 100);
        setTimeout(
          function () {
            this.dismissalert = false;
          }.bind(this),
          3000
        );
      this.UserGroup();
        this.spinner.hide();
      } else {
        this.notify = "Something Went Wrong. Try again.!!";
        setTimeout(() => (this.dismissalert = true), 100);
        setTimeout(
          function () {
            this.dismissalert = false;
          }.bind(this),
          3000
        );
        this.UserGroup();
        this.spinner.hide();
      }
    });
  }
  closealert(){
    this.dismissalert=false;
  }
}
