import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DownloadFileVM } from 'src/app/ViewModels/DownloadFileVM';
import { FileviewVM } from 'src/app/ViewModels/FileviewVM';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminDashboardService {

  readonly rooturl1 = "https://blocdrive.com/blocdriveapi";

  constructor(private http: HttpClient) { }
  getdashboardProjectsSummary(id){
    return this.http.get(this.rooturl1 + '/api/AdminDashboard/GetDashboardSummary/' + id );
  }
  getdashboardAllProjects(id){
    return this.http.get(this.rooturl1 + '/api/AdminDashboard/GetDashboardAllProjects/' + id );
  }
  getdashboardProjects(Statusuid){
    return this.http.get(this.rooturl1 + '/api/AdminDashboard/GetDashboardProjects/' + Statusuid);
  }
  getProjData(pid){
    return this.http.get(this.rooturl1 + '/api/AdminDashboard/GetProjectData/' + pid);
  }
  getProjectStage(projectId) {
    return this.http.get(this.rooturl1 + "/api/ProjectStages/GetCurrentStage/" + projectId);
  }
  getprojecttasks(projectId) {
    return this.http.get(this.rooturl1 + '/api/AddTask/GetProjectTasks/' + projectId);
  }
 
  GetUserName(projectLeadId) {
    return this.http.get(this.rooturl1 + "/api/AddProject/GetUserName/" + projectLeadId);
  }
  getProjectstatus(projectId) {
    return this.http.get(this.rooturl1 + '/api/ProjectStatus/GetCurrentStatus/' + projectId);
  }
  getProjectModules(projectId){
    return this.http.get(this.rooturl1 + '/api/ProjectModules/GetProjectModules/' + projectId);
  }
  getCurrentProjectComments(projectId) {
    return this.http.get(this.rooturl1 + '/api/TaskComments/GetCurrentProjectComments/' + projectId);
  }
  getProjectUsers(projectId) {
    return this.http.get(this.rooturl1 + '/api/AddProject/GetProjectUsers/' + projectId);
  }

  getProjectDocumentsList(id) {
    return this.http.get(this.rooturl1 + '/api/AddProject/GetProjectDocuments/' + id);
  }
  savestage(stage){
    return this.http.post(this.rooturl1 + '/api/ProjectStages/PosttblProjectStage',stage );
  }
  getstages() {
    return this.http.get(this.rooturl1 + '/api/ProjectStages/GettblStages');
  }
  getstatus() {
    return this.http.get(this.rooturl1 + '/api/ProjectStatus/GettblStatus');
  }
  getProject(){
    return this.http.get(this.rooturl1 + '/api/project/Getprojectlist/');
  }
  updateTask(addTask){
    return this.http.post(this.rooturl1 + '/api/AddTask/UpdateTask/', addTask);
  }
  savestatus(status){
    return this.http.post(this.rooturl1 + '/api/ProjectStatus/PosttblProjectStatu',status );
  }
  savecomment(comment){
    return this.http.post(this.rooturl1 + '/api/TaskComments/PosttblTaskComment',comment );
  }
  savemoduledata(Addmodule){
    return this.http.post(this.rooturl1 + '/api/ProjectModules/PosttblProjectModule',Addmodule );
  }
  saveprojectuser(projectrole){
    return this.http.post(this.rooturl1 + '/api/AddProject/SaveProjectUser',projectrole );
  }
  getProjectRoles(id){
    return this.http.get(this.rooturl1 + '/api/AddProject/GetProjectRoles/' +id);
  }
  getalldocuments(id){
    return this.http.get(this.rooturl1 + '/api/Documents/GetDocumentsForProjects/' +id);

  }
  downloadFile(data:DownloadFileVM){
    return this.http.post(this.rooturl1 + '/api/Documents/PostDownloadProjectFiles', data);
  }
  getdocforpdfview(data: FileviewVM) {
    return this.http.post(this.rooturl1 + "/api/Documents/PostdocforProjectView", data);
  }
  getpdffiletoview(data){
    return this.http.post(this.rooturl1 + "/api/Documents/PostdocforProjectView", data);

  }
  GetLatestComments(id){
    return this.http.get(this.rooturl1 + '/api/Account/GetDocumentsLatestCommnet/' +id);

  }

  getProjectTeamName(id) {
    return this.http.get(this.rooturl1 + '/api/AddProject/GetProjectTeamName/' + id);
  }
  getProjectTeamMembers(id) {
    return this.http.get(this.rooturl1 + '/api/AddProject/GetProTeamMembers/' + id);
  }
  searchPdf( data: any): Observable<any> {
    return this.http.post<any>("https://blocdrive.com/aisearch/search", data);
  }
  getbloburl(id : any){
    return this.http.get('https://blocdrive.com/blocdriveapi/api/' + "UploadFile/getbloburlaisearch/"+ id)
  }
}
