import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class EncryptionService {
  private secretKey = CryptoJS.enc.Utf8.parse('1234567890123456'); // 16-byte key
  private iv = CryptoJS.enc.Utf8.parse('1234567890123456'); // 16-byte IV
  // Encrypt
  encryptData(data: any): string {
    const encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      this.secretKey,
      {
        iv: this.iv,
        mode: CryptoJS.mode.CBC, // Set CBC mode
        padding: CryptoJS.pad.Pkcs7, // Explicit padding
      }
    );
    return encrypted.toString(); // Return Base64 string
  }
  // Decrypt
  decryptData(encryptedText: string): any {
    const decrypted = CryptoJS.AES.decrypt(encryptedText, this.secretKey, {
      iv: this.iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    const decryptedText = decrypted.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedText); // Convert string to object
  }
}